import { useEffect, useState } from "react"
import { useRouter } from "next/router"
import { removeAndCleanLinkConcat } from "@/utils/helper"
import styles from "@/components/Shared/index.module.scss"

const AdditionalFooter = props => {
  const { data, columnClassNames } = props

  const router = useRouter()
  const [location, setLocation] = useState("")
  const [docTitle, setDocTitle] = useState("")

  useEffect(() => {
    setLocation(window.location.href)
    setDocTitle(document.title)
  }, [router.pathname])

  const getFooterLinkEvent = item => {
    const link = item?.authoredLink
      ? (typeof window !== "undefined" && item?.authoredLink?.indexOf("http") === -1
          ? window.location.origin
          : "") + removeAndCleanLinkConcat(item?.authoredLink)
      : "n/a"
    const eventInfo = {
      clickInternalLinks: item?.newTab ? "false" : "true",
      eventAction: `global footer:${item?.linkLabel?.toLowerCase()}`,
      eventName: `global footer:${item?.linkLabel?.toLowerCase()}`,
      eventType: "footer menu-2",
      internalLinkName: item?.linkLabel?.toLowerCase(),
      internalLinkPosition: "global footer",
      internalLinkType: "global footer:navigation",
      internalLinkURL: link,
      internalLinkZoneName: "global footer",
    }
    return JSON.stringify(eventInfo)
  }

  const getSocialLinkEvent = (siteName = "", siteLink = "/") => {
    const eventInfo = {
      clickInternalLinks: "false",
      eventAction: `social media:${siteName.toLowerCase()}`,
      eventName: `social media:${siteName.toLowerCase()}`,
      eventType: "social icon",
      internalLinkName: `social media:${siteName.toLowerCase()}`,
      internalLinkPosition: "global footer",
      internalLinkType: "global footer:navigation",
      internalLinkURL: siteLink,
      internalLinkZoneName: "global footer",
    }
    return JSON.stringify(eventInfo)
  }

  return (
    <div
      className={`${columnClassNames ? columnClassNames : ""} ${
        styles.additionalFooterWrapper
      }`}
    >
      <div className="additional-footer">
        <footer>
          <section className="gbh-footer-add-nav">
            <section className="gbh-container">
              <section className="gbh-footer-add-panel">
                <ul className="gbh-footer-nav__links">
                  {data?.items &&
                    data?.items.map((item, index) => (
                      <li key={`additionalfooteritem-${index}`}>
                        <a
                          id={item?.idLabel}
                          className={item?.classLabel}
                          aria-label={item.linkLabel}
                          target={item.newTab ? "_blank" : "_self"}
                          href={removeAndCleanLinkConcat(item.authoredLink)}
                          rel="noreferrer"
                          data-gbh-data-layer={getFooterLinkEvent(item)}
                        >
                          {item.linkLabel}
                        </a>
                      </li>
                    ))}
                </ul>
                <p className="gbh-copy__rights">{data.copyright}</p>
                <ul className="gbh-social__links">
                  {data?.email == "email" && (
                    <li className="gbh-social__email">
                      <a
                        target="_blank"
                        className="gbh-data-layer"
                        aria-label="Email corporate page link opens in a new tab"
                        href={`mailto:?body=${location}&subject=${docTitle}`}
                        rel="noreferrer noopener"
                        title={data.linkLabelEmail}
                        data-gbh-data-layer={getSocialLinkEvent(data?.linkLabelEmail, data?.linkEmail)}
                      ></a>
                    </li>
                  )}
                  {data?.facebook == "facebook" && (
                    <li className="gbh-social__facebook">
                      <a
                        target="_blank"
                        aria-label="Facebook corporate page link opens in a new tab"
                        href={data.linkFacebook}
                        rel="noreferrer noopener"
                        title={data?.linkLabelFacebook}
                        data-gbh-data-layer={getSocialLinkEvent(data?.linkLabelFacebook, data?.linkFacebook)}
                        className="gbh-data-layer"
                      ></a>
                    </li>
                  )}
                  {data?.twitter == "twitter" && (
                    <li className="gbh-social__twitter">
                      <a
                        target="_blank"
                        aria-label="Twitter corporate page link opens in a new tab"
                        href={data.linkTwitter}
                        rel="noreferrer noopener"
                        title={data.linkLabelTwitter}
                        data-gbh-data-layer={getSocialLinkEvent(data?.linkLabelTwitter, data?.linkTwitter)}
                        className="gbh-data-layer"
                      ></a>
                    </li>
                  )}
                  {data?.instagram == "instagram" && (
                    <li className="gbh-social__insta">
                      <a
                        target="_blank"
                        aria-label="Instagram corporate page link opens in a new tab"
                        href={data.linkInstagram}
                        rel="noreferrer noopener"
                        title={data.linkLabelInstagram}
                        data-gbh-data-layer={getSocialLinkEvent(data?.linkLabelInstagram, data?.linkInstagram)}
                        className="gbh-data-layer"
                      ></a>
                    </li>
                  )}
                  {data?.pinterest == "pinterest" && (
                    <li className="gbh-social__pintrest">
                      <a
                        target="_blank"
                        aria-label="Pinterest corporate page link opens in a new tab"
                        href={data.linkPinterest}
                        rel="noreferrer noopener"
                        title={data.linkLabelPinterest}
                        data-gbh-data-layer={getSocialLinkEvent(data?.linkLabelPinterest, data?.linkPinterest)}
                        className="gbh-data-layer"
                      ></a>
                    </li>
                  )}
                  {data?.youtube == "youtube" && (
                    <li className="gbh-social__youtube">
                      <a
                        target="_blank"
                        aria-label="Youtube corporate page link opens in a new tab"
                        href={data.linkYoutube}
                        rel="noreferrer noopener"
                        title={data.linkLabelYoutube}
                        data-gbh-data-layer={getSocialLinkEvent(data?.linkLabelYoutube, data?.linkYoutube)}
                        className="gbh-data-layer"
                      ></a>
                    </li>
                  )}
                  {data?.houzz == "houzz" && (
                    <li className="gbh-social__houzz">
                      <a
                        target="_blank"
                        aria-label="Houzz corporate page link opens in a new tab"
                        href={data.linkHouzz}
                        rel="noreferrer noopener"
                        title={data.linkLabelHouzz}
                        data-gbh-data-layer={getSocialLinkEvent(data?.linkLabelHouzz, data?.linkHouzz)}
                        className="gbh-data-layer"
                      ></a>
                    </li>
                  )}
                  {data?.linkedin == "linkedin" && (
                    <li className="gbh-social__linkedin">
                      <a
                        target="_blank"
                        aria-label="Linkedin corporate page link opens in a new tab"
                        href={data.linkLinkedin}
                        rel="noreferrer noopener"
                        title={data.linkLabelLinkedin}
                        data-gbh-data-layer={getSocialLinkEvent(data?.linkLabelLinkedin, data?.linkLinkedin)}
                        className="gbh-data-layer"
                      ></a>
                    </li>
                  )}
                </ul>
              </section>
            </section>
          </section>
        </footer>
      </div>
    </div>
  )
}

export default AdditionalFooter
