import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import _isEmpty from "lodash/isEmpty"
import { useRouter } from "next/router"
import { getConfig } from "@/constants/config"
import {
  convertStringToBoolean,
  getOffsetTop,
  getShortenedUrlwithGeneral,
  isAuthenticated,
} from "@/utils/helper"
import {
  selectAuthState,
  setAuthModalVisibility,
  setHideCustomerSignupCTA,
} from "@/store/features/authSlice"
import { selectGenericState } from "@/store/features/genericSlice"
import {
  DEFAULT_SCROLL,
  FIND_A_PRO_HASH,
  MORE_OPTIONS,
  MY_ACCOUNT_PATH,
  INSTALLATION_SERVICES_HASH,
} from "@/constants"
import { addTeaserActionAnalytics } from "@/components/Default/Teaser/v1/analytics"

const Action = props => {
  const {
    primaryCTA = "",
    secondaryCTA = "",
    playVideoCTA = "",
    videoTitle = "",
    mainTitle = "",
    link = "",
    text = "",
    newTab = "",
    urlRef = "",
    id = "",
    pageType = "",
    linkAll = "",
    componentPosition = null,
    eventName = null,
    eventAction = null,
    dataLayerLinkType = `${pageType}:${mainTitle}`,
    productCategory = null,
    productCollectionsName = null,
    productRoom = null,
    propertiesPageType = "",
    playVideoCTAcb = () => "",
    index = 0,
    compInfo,
    activeIndex = 0,
    setActive = () => "",
  } = props
  const { scroll, findAPro, installationServices } = DEFAULT_SCROLL
  const [general, setGeneral] = useState(null)

  const dispatch = useDispatch()
  const router = useRouter()
  const { isAuth } = useSelector(selectAuthState)
  const { pageType: datalayerPageType = "" } = useSelector(selectGenericState)
  const [actionLink, setActionLink] = useState("")
  const [target, setTarget] = useState("")
  const [loginRegisterCallback, setLoginRegisterCallback] = useState(null)

  useEffect(() => {
    if (
      loginRegisterCallback &&
      isAuth &&
      router.pathname.includes(MY_ACCOUNT_PATH)
    ) {
      handleOnLinkClick(loginRegisterCallback)
    }
  }, [loginRegisterCallback, isAuth])

  useEffect(() => {
    getConfig().then(async config => {
      setGeneral(config?.general)
    })
  }, [])

  useEffect(() => {
    if (!_isEmpty(link) && !_isEmpty(general)) {
      const actLink = getShortenedUrlwithGeneral(link, general)
      setActionLink(actLink)
    }
  }, [link, general])

  const handleLogin = e => {
    if (router.pathname.includes(MY_ACCOUNT_PATH)) {
      e.preventDefault()
      e.stopPropagation()
      window.loginRegisterCallback = () => {
        setLoginRegisterCallback(e)
        if (actionLink) router.push(actionLink)
      }
      dispatch(setAuthModalVisibility({ show: true }))
    } else {
      handleOnLinkClick()
    }
  }

  const handleOnLinkClick = event => {
    return propertiesPageType == "modalWindow" && playVideoCTA
      ? playVideoCTAcb(event)
      : undefined
  }

  const handleOnClick = event => {
    const actionLink = event?.target?.getAttribute("href") ?? ""
    if (actionLink?.startsWith("#KPN")) {
      event.preventDefault()
      const link = actionLink.split("::")
      const id = link[0]
      const url = link[1]
      if (link) {
        dispatch(setHideCustomerSignupCTA(true))
        if (id?.includes("signIn")) {
          window.KPNSignIn = true
          isAuthenticated(url, 0, true)
        } else if (id?.includes("createAccount")) {
          window.KPNSignIn = false
          isAuthenticated(url, 1, true)
        } else {
          isAuthenticated(url)
        }
      }
    }
    const currentIndex = Number(event.target.getAttribute("data-index")) || 0
    setActive(currentIndex)
    if (
      event.target.getAttribute("href").startsWith("#") &&
      Object.values(MORE_OPTIONS).includes(event.target.text)
    ) {
      exploreMoreScroll(event)
    } else if (router.pathname.includes(MY_ACCOUNT_PATH) && !isAuth) {
      handleLogin(event)
    } else {
      handleOnLinkClick(event)
    }
    addTeaserActionAnalytics({
      newTab,
      pageType,
      componentPosition,
      videoTitle,
      link,
      text,
      eventName,
      dataLayerLinkType,
      eventAction,
      productCategory,
      productCollectionsName,
      productRoom,
      datalayerPageType,
      compInfo,
      mainTitle,
    })
  }

  // global explore more scroll
  const exploreMoreScroll = e => {
    e.preventDefault()

    const hash = e.target.hash
    if (hash === "") return

    const element = document.querySelector(hash)
    if (!element) return

    const offsetTop =
      getOffsetTop(element) -
      (hash === FIND_A_PRO_HASH
        ? findAPro
        : hash === INSTALLATION_SERVICES_HASH
        ? installationServices
        : scroll)
    window.scrollTo({
      top: offsetTop,
      behavior: "smooth",
    })
  }

  // Added to fix eslint error Disallow target="_blank" attribute without rel="noreferrer" (react/jsx-no-target-blank)
  useEffect(() => {
    if (newTab === false || newTab === "false") {
      setTarget("_self")
    } else {
      setTarget("_blank")
    }
  }, [newTab])

  if (!linkAll) {
    return (
      <a
        className={`cmp-teaser__action-link ${
          convertStringToBoolean(primaryCTA) ? "gbh-primary-button " : ""
        }${
          convertStringToBoolean(secondaryCTA) ? "gbh-secondary-button " : ""
        }${
          propertiesPageType === "modalWindow" && playVideoCTA
            ? "video-play-button "
            : ""
        } ${index === activeIndex ? "active" : ""}`}
        role="button"
        aria-describedby={id}
        href={actionLink}
        aria-label={text}
        target={target}
        rel={urlRef ? "noreferrer" : ""}
        onClick={handleOnClick}
        data-index={index}
      >
        {text}
      </a>
    )
  }

  return (
    <div
      className={`cmp-teaser__action-link ${
        convertStringToBoolean(primaryCTA) ? "gbh-primary-button " : ""
      }${convertStringToBoolean(secondaryCTA) ? "gbh-secondary-button " : ""}${
        pageType == "modalWindow" && playVideoCTA ? "video-play-button " : ""
      }`}
      role="button"
      aria-describedby={id || ""}
      rel={urlRef ? "noreferrer" : ""}
      aria-label={text}
    >
      {text}
    </div>
  )
}

export default Action
