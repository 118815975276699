import { useTranslation } from "next-i18next"

const useSearchi18n = ({ componentProp }) => {
  const { t } = useTranslation()
  const i18n = {
    searchPlaceHolder: t("kf.technical.label.searchPlaceHolder"),
    for: t("kf.search.label.for"),
    searchInstead: t("kf.search.label.searchInstead"),
    showing: t("kf.search.label.showing"),
    results: t("kf.search.label.results"),
    resultsCaps: t("kf.search.label.resultsCaps"),
    addToCart: t("kf.compare.label.addToCart"),
    products: t("kf.search.label.products"),
    parts: t("kf.search.label.parts"),
    technical: t("kf.search.label.technical"),
    inspiration: t("kf.search.label.inspiration"),
    resource: t("kf.search.label.resource"),
    help: t("kf.search.label.help"),
    print: t("kf.plp.label.print"),
    share: t("kf.plp.label.share"),
    wishlist: t("kf.plp.label.wishlist"),
    showFilter: t("kf.plp.label.showFilter"),
    hideFilter: t("kf.plp.label.hideFilter"),
    filters: t("kf.plp.label.filters"),
    filterSort: t("kf.plp.label.filterSort"),
    addToCompare: t("kf.plp.label.addToCompare"),
    colors: t("kf.plp.label.colors"),
    color: t("kf.plp.label.color"),
    view: t("kf.plp.label.view"),
    all: t("kf.plp.label.all"),
    sort: t("kf.plp.label.sort"),
    applyFilters: "Apply Filters",
    loadMore: t("kf.plp.label.loadMore"),
    backToTop: t("kf.plp.label.backToTop"),
    showMore: t("kf.plp.label.showMore"),
    showLess: t("kf.plp.label.showLess"),
    clearAll: t("kf.plp.label.clearAll"),
    priceRangeTitle: t("kf.plp.label.priceRangeTitle"),
    priceRangeDesc: t("kf.plp.label.priceRangeDesc"),
    to: t("kf.plp.label.to"),
    minText: t("kf.plp.label.minText"),
    maxText: t("kf.plp.label.maxText"),
    bestSellers: t("kf.plp.label.bestSellers"),
    featured: t("kf.plp.label.featured"),
    onSale: t("kf.plp.label.onSale"),
    relevancy: t("kf.plp.label.relevancy"),
    date: t("kf.plp.label.date"),
    priceDesc: t("kf.plp.label.priceDesc"),
    priceAsc: t("kf.plp.label.priceAsc"),
    atoz: t("kf.plp.label.atoz"),
    ztoa: t("kf.plp.label.ztoa"),
    gridView: t("kf.plp.label.gridView"),
    columnView: t("kf.plp.label.columnView"),
    sameCategory: t("kf.compare.validation.sameCategory"),
    maxProduct: t("kf.compare.validation.maxProducts"),
    errors: {
      validRange: t("kf.plp.error.validRange"),
      minGreaterThanMax: t("kf.plp.error.minGreaterThanMax"),
      maxGreaterThanZero: t("kf.plp.error.maxGreaterThanZero"),
      checkValid: t("kf.error.message.price"),
    },
    new: t("kf.plp.label.new"),
    sale: t("kf.plp.label.sale"),
    category: t("kf.technical.label.category"),
    collection: t("kf.technical.label.collection"),
    certifications: t("kf.technical.label.certifications"),
    designFiles: t("kf.technical.label.designFiles"),
    technicalInfo: t("kf.pdp.label.specs"),
    installationSupport: t("kf.pdp.label.installationSupport"),
    addToFolder: t("kf.technical.label.addToFolder"),
    selectAll: t("kf.technical.label.selectAll"),
    download: t("kf.technical.label.download"),
    hideDiscontinued: t("kf.plp.label.hideDiscontinued"),
    file: t("kf.technical.label.file"),
    files: t("kf.technical.label.files"),
    specsSheet: t("kf.technical.label.specsSheet"),
    envDeclaration: t("kf.technical.label.envDeclaration"),
    installationGuide: t("kf.technical.label.installationGuide"),
    userGuide: t("kf.technical.label.userGuide"),
    homeOwnerGuide: t("kf.technical.label.homeOwnerGuide"),
    declareLabel: t("kf.technical.label.declareLabel"),
    tearSheet: t("kf.technical.label.tearSheet"),
    quickReference: t("kf.technical.label.quickReference"),
    warranty: t("kf.technical.label.warranty"),
    serviceParts: t("kf.technical.label.serviceParts"),
    suggestions: t("kf.search.label.suggestions"),
    checkSpelling: t("kf.search.label.checkSpelling"),
    simplerWord: t("kf.search.label.simplerWord"),
    searchSimilar: t("kf.search.label.searchSimilar"),
    popularSearch: t("kf.search.label.trendingSearch"),
    commonTopics: t("kf.search.label.commonTopics"),
    noResults: t("kf.search.label.noResults"),
    loading: t("kf.search.label.loading"),
    twoDplan: t("kf.technical.label.twoDplan"),
    twoDside: t("kf.technical.label.twoDside"),
    twoDfront: t("kf.technical.label.twoDfront"),
    threeDfile: t("kf.technical.label.threeDfile"),
    bimFile: t("kf.technical.label.bimFile"),
    cutoutTemplate: t("kf.technical.label.cutoutTemplate"),
    viewResults: t("kf.plp.label.viewResults"),
    popularSearchText: t("kf.search.label.trendingSearch"),
    exclusive: t("kf.plp.label.exclusive"),
    newExclusive: t("kf.plp.label.newExclusive"),
    favSuccessMsg: t("kf.favorites.success"),
    favErrorMsg: t("kf.favorites.error"),
    searchBrand: t("kf.search.label.searchBrand"),
    brand: t("kf.search.label.brand"),
    items: t("kf.plp.label.items"),
    etc: t("kf.plp.label.etc"),
    sortbyExpanded: t("kf.plp.label.sortbyExpanded"),
    sortbyClosed: t("kf.plp.label.sortbyClosed"),
    selected: t("kf.plp.arialabel.selected"),
    altSort: t("kf.plp.label.img.altSort"),
    discontinuedText: t("kf.pdp.label.discontinuedTxt"),
    partsDiagram: t("kf.pdp.label.partsDiagram"),
    guides: t("kf.technical.label.guides"),
    sortByAria: count => t("kf.plp.label.sortByAria", { sortByCount: count }),
    ariaLabel: {
      tabs: (count, tabName) =>
        t("kf.search.aria.label.tabs", { count, tabName }),
      loadMore: t("kf.search.aria.label.loadMore"),
      loadMoreArticles: t("kf.search.aria.label.loadMoreArticles"),
      close: t("kf.search.aria.label.clear"),
      addToCompare: product => t("kf.plp.label.aria.addToCompare", { product }),
      currentPage: t("kf.pdp.label.aria.currentPage"),
      page: t("kf.pdp.label.aria.page"),
      columnViewSelected: t("kf.productTab.aria.columnViewSelected"),
      columnViewNotSelected: t("kf.productTab.aria.columnViewNotSelected"),
      gridViewSelected: t("kf.productTab.aria.gridViewSelected"),
      gridViewNotSelected: t("kf.productTab.aria.gridViewNotSelected"),
      breadcrumb: t("kf.pdp.label.aria.breadcrumb"),
    },
    home: t("kf.technical.label.home"),
    searchResults: t("kf.technical.label.searchResults"),
    searchText: t("kf.search.label.searchText"),
    searchNoResultErrorMessage: componentProp?.searchFilterErrorMsg
      ? componentProp?.searchFilterErrorMsg
      : t("kf.search.label.noResultsMssg"),
    noResults: t("kf.search.label.noResults"),
    filterTitle: (facetName, facetCount) =>
      t("kf.plp.ariaLabel.filterTitle", {
        facetName: facetName,
        facetCount: facetCount,
      }),
    arialLabelPrint: val => t("kf.plp.label.aria.print", { val: val }),
    ariaLabelShare: val =>
      t("kf.plp.label.aria.share", {
        val: `${val} page`,
      }),
    favorites: t("kf.account.label.favorites"),
    removeItem: t("kf.favorites.removeItem"),
    downloadSuccess: t("kf.PressRoom.label.downloadSuccess"),
    downloadFailure: t("kf.PressRoom.label.downloadFailure"),
    installationAvailable: t("kf.pdp.label.installationAvailable"),
  }

  return i18n
}

export default useSearchi18n
