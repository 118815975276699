import { pushAnalyticsObjToDataLayer } from "@/utils/helper"
const pageType = "find a store"
export const breadcrumbAnalytics = navItem => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${pageType}:landing:hero banner:breadcrumb`,
    eventName: `${pageType}:landing:  :hero banner:breadcrumb`,
    eventType: "navigation",
    internalLinkName: "home>find a store",
    internalLinkPosition: pageType,
    internalLinkType: `${pageType}:navigation`,
    internalLinkZoneName: `${pageType}:landing:hero banner`,
    internalLinkURL: navItem?.url ?? "n/a",
  }
  pushAnalyticsObjToDataLayer(eventInfo)
}
export const kecBreadcrumbAnalytics = navItem => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${pageType}:kohler experience centres:hero banner:breadcrumb`,
    eventName: `${pageType}:kohler experience centres:hero banner:breadcrumb`,
    eventType: "navigation",
    internalLinkName: "home>find a store",
    internalLinkPosition: pageType,
    internalLinkType: `${pageType}:breadcrumb`,
    internalLinkZoneName: `${pageType}:kohler experience centres:hero banner`,
    internalLinkURL: navItem?.url ?? "n/a",
  }
  pushAnalyticsObjToDataLayer(eventInfo)
}
export const stateSelectionAnalytics = state => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${pageType}:landing:location info:select state`,
    eventName: `${pageType}:landing:location info:select state`,
    eventType: "navigation",
    internalLinkName: state.toLowerCase(),
    internalLinkPosition: pageType,
    internalLinkType: `${pageType}:navigation`,
    internalLinkZoneName: `${pageType}:landing:location info`,
    internalLinkURL: "n/a",
  }
  pushAnalyticsObjToDataLayer(eventInfo)
}
export const citySelectionAnalytics = city => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${pageType}:landing:location info:select city`,
    eventName: `${pageType}:landing:location info:select city`,
    eventType: "navigation",
    internalLinkName: city.toLowerCase(),
    internalLinkPosition: pageType,
    internalLinkType: `${pageType}:navigation`,
    internalLinkZoneName: `${pageType}:landing:location info`,
    internalLinkURL: "n/a",
  }
  pushAnalyticsObjToDataLayer(eventInfo)
}

export const accordionSelectionAnalytics = () => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${pageType}:landing:location info:expandOrCollapse`,
    eventName: `${pageType}:landing:location info:expandOrCollapse`,
    eventType: "navigation",
    internalLinkName: "AccordionExpandOrCollapse",
    internalLinkPosition: pageType,
    internalLinkType: `${pageType}:exclusive showrooms`,
    internalLinkZoneName: `${pageType}:landing:location info`,
    internalLinkURL: "n/a",
  }
  pushAnalyticsObjToDataLayer(eventInfo)
}
export const contactClickAnalytics = (number, pinCode, storeName) => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${pageType}:landing:accordion:exclusive showrooms:contact number`,
    eventName: `${pageType}:landing:accordion:exclusive showrooms:contact number`,
    eventType: "navigation",
    internalLinkName: number,
    internalLinkPosition: pageType,
    internalLinkType: `${pageType}:${pinCode}:${storeName.toLowerCase()}`,
    internalLinkZoneName: `${pageType}:landing:accordion:exclusive showrooms`,
    internalLinkURL: `tel:${number}`,
  }
  pushAnalyticsObjToDataLayer(eventInfo)
}
export const emailClickAnalytics = (email, pinCode, storeName) => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${pageType}:landing:accordion:exclusive showrooms:email`,
    eventName: `${pageType}:landing:accordion:exclusive showrooms:email`,
    eventType: "navigation",
    internalLinkName: email,
    internalLinkPosition: pageType,
    internalLinkType: `${pageType}:${pinCode}:${storeName.toLowerCase()}`,
    internalLinkZoneName: `${pageType}:landing:accordion:exclusive showrooms`,
    internalLinkURL: `mailto:${email}`,
  }
  pushAnalyticsObjToDataLayer(eventInfo)
}
export const backToTopAnalytics = () => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${pageType}:landing:location info:back to top`,
    eventName: `${pageType}:landing:location info:back to top`,
    eventType: "navigation",
    internalLinkName: "back to top",
    internalLinkPosition: pageType,
    internalLinkType: `${pageType}:navigation`,
    internalLinkZoneName: `${pageType}:landing:location info`,
    internalLinkURL: "n/a",
  }
  pushAnalyticsObjToDataLayer(eventInfo)
}
