import { pushAnalyticsObjToDataLayer } from "@/utils/helper"

export const verifyOtpAnalytics = (page, status, statusMsg) => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${page}:mobile number verification modal:verify`,
    eventName: `${page}:mobile number verification modal:verify`,
    eventType: "navigation",
    eventStatus: status,
    eventMsg: statusMsg ?? "n/a",
    internalLinkName: "verify",
    internalLinkPosition: page,
    internalLinkType: `${page}:${page === "support:contact" ? 'service & support' : 'navigation'}`,
    internalLinkZoneName: `${page}:${
      page === "my account:register offline kohler products"
        ? "personal information:mobile number verification modal"
        : "mobile number verification modal"
    }`,
    internalLinkURL: "n/a",
  }
  pushAnalyticsObjToDataLayer(eventInfo)
}

export const changeNumberAnalytics = page => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${page}:mobile number verification modal:edit number`,
    eventName: `${page}:mobile number verification modal:edit number`,
    eventType: "navigation",
    internalLinkName: "edit number",
    internalLinkPosition: page,
    internalLinkType: `${page}:${page === "support:contact" ? 'service & support' : 'modal'}`,
    internalLinkZoneName: `${page}:mobile number verification modal`,
    internalLinkURL: "n/a",
  }
  pushAnalyticsObjToDataLayer(eventInfo)
}

export const resendOtpAnalytics = page => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${page}:mobile number verification modal:resend otp`,
    eventName: `${page}:mobile number verification modal:resend otp`,
    eventType: "navigation",
    internalLinkName: "resend otp",
    internalLinkPosition: page,
    internalLinkType: `${page}:${page === "support:contact" ? 'service & support' : 'modal'}`,
    internalLinkZoneName: `${page}:mobile number verification modal`,
    internalLinkURL: "n/a",
  }
  pushAnalyticsObjToDataLayer(eventInfo)
}

export const closeOtpAnalytics = page => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${page}:mobile number verification modal:close`,
    eventName: `${page}:mobile number verification modal:close`,
    eventType: "navigation",
    internalLinkName: "close",
    internalLinkPosition: page,
    internalLinkType: `${page}:${page === "support:contact" ? 'service & support' : 'navigation'}`,
    internalLinkZoneName: `${page}:${
      page === "my account:register offline kohler products"
        ? "personal information:mobile number verification modal"
        : "mobile number verification modal"
    }`,
    internalLinkURL: "n/a",
  }
  pushAnalyticsObjToDataLayer(eventInfo)
}

export const getOtpAnalytics = page => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${page}:mobile number verification modal:get otp`,
    eventName: `${page}:mobile number verification modal:get otp`,
    eventType: "navigation",
    internalLinkName: "get otp",
    internalLinkPosition: page,
    internalLinkType: `${page}:${page === "support:contact" ? 'service & support' : 'modal'}`,
    internalLinkZoneName: `${page}:mobile number verification modal`,
    internalLinkURL: "n/a",
  }
  pushAnalyticsObjToDataLayer(eventInfo)
}

export const wantToLeaveAnalytics = (page, choice) => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${page}:mobile number verification modal:want to leave modal:${choice}`,
    eventName: `${page}:mobile number verification modal:want to leave modal:${choice}`,
    eventType: "navigation",
    internalLinkName: choice,
    internalLinkPosition: page,
    internalLinkType: `${page}:${page === "support:contact" ? 'service & support' : 'modal'}`,
    internalLinkZoneName: `${page}:mobile number verification modal:want to leave modal`,
    internalLinkURL: "n/a",
  }
  pushAnalyticsObjToDataLayer(eventInfo)
}

export const confirmCloseAnalytics = page => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${page}:mobile number verification modal:want to leave modal:close`,
    eventName: `${page}:mobile number verification modal:want to leave modal:close`,
    eventType: "navigation",
    internalLinkName: "close",
    internalLinkPosition: page,
    internalLinkType: `${page}:${page === "support:contact" ? 'service & support' : 'modal'}`,
    internalLinkZoneName: `${page}:mobile number verification modal:want to leave modal`,
    internalLinkURL: "n/a",
  }
  pushAnalyticsObjToDataLayer(eventInfo)
}

export const privacyStatementAnalytics = (page, text, link) => {
  const linkUrl =
    (link.indexOf("http") === -1 ? window.location.origin : "") + link
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${page}:mobile number verification modal:${text}`,
    eventName: `${page}:mobile number verification modal:${text}`,
    eventType: "navigation",
    internalLinkName: text,
    internalLinkPosition: page,
    internalLinkType: `${page}:service & support`,
    internalLinkZoneName: `${page}:mobile number verification modal`,
    internalLinkURL: linkUrl,
  }
  pushAnalyticsObjToDataLayer(eventInfo)
}
