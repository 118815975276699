import React, { useState, useEffect, useRef } from "react"
import cx from "classnames"
import CONSTANTS from "@/constants/index"
import styles from "@/components/core/Autocomplete/Autocomplete.module.scss"

const Autocomplete = props => {
  const {
    customClass,
    id,
    tabIndex,
    label,
    ariaLabel,
    ariaRequired,
    value,
    ariaStateText,
    data,
    showError,
    errorMessage,
  } = props

  const [dataList, setDataList] = useState([])

  useEffect(() => {
    if (data) {
      const sortData = data.sort((a, b) =>
        a?.name < b?.name ? -1 : a?.name > b?.name ? 1 : 0
      )
      setDataList(sortData)
    }
  })

  const inputRef = useRef(null)
  const listRef = useRef(null)
  const [showList, setShowList] = useState(false)
  const [opened, setOpened] = useState(false)
  const [options, setOptions] = useState([])
  const [focus, setFocus] = useState(false)
  let isFocused = false
  const dropDownClass = cx({
    "select-dropdown": true,
    "dropdown--open": showList,
  })

  const inputClasses = cx(
    "select-dropdown__border",
    props.disabled ? "--disabled" : null,
    props.showError ? "--with-error" : null,
    focus ? "--focused" : null
  )

  useEffect(() => {
    if (showList) {
      setOpened(true)
    } else if (!showList && opened && props.onClose) {
      props.onClose()
      setOpened(false)
    }
  }, [showList])

  useEffect(() => {
    if (props?.focus) {
      inputRef.current.focus()
    }
  }, [props?.focus])

  const handleSelect = value => {
    props.onChange(value)
    setShowList(false)
  }

  const handleMouseDownEvent = () => {
    isFocused = true
    setShowList(!showList)
  }

  const handleBlurEvent = e => {
    if (e instanceof MouseEvent) {
      isFocused
      setShowList(false)
    }
    if (typeof props.onBlur === "function") {
      props.onBlur()
    }
  }

  const toggleList = () => {
    if (showList) {
      setShowList(false)
      document.getElementById(props.id).focus()
    } else {
      setShowList(true)
      document.getElementById(`${props.id}-list`)?.childNodes?.[0]?.focus()
    }
  }

  const btnKeyDown = e => {
    const { KEYCODE } = CONSTANTS
    switch (e.keyCode) {
      case KEYCODE.SPACE:
        break
      case KEYCODE.ENTER:
        e.preventDefault()
        toggleList()
        break
      default:
        break
    }
  }

  const handleKeyDown = (i, e) => {
    const { KEYCODE } = CONSTANTS
    const ele = document.getElementById(`${props.id}-list`).childNodes
    switch (e.keyCode) {
      case KEYCODE.ESC:
        toggleList()
        break
      case KEYCODE.UP:
        if (i > 0) ele[i - 1].focus()
        break
      case KEYCODE.DOWN:
        if (i < ele.length - 1) ele[i + 1].focus()
        break
      case KEYCODE.SPACE:
        break
      case KEYCODE.ENTER:
        ele[i].click()
        document.getElementById(props.id).focus()
        break
      default:
        break
    }
  }

  useEffect(() => {
    const indexValue = dataList.indexOf(
      dataList.find(val => val?.name === value)
    )

    const filteredData = dataList?.filter(
      ({ name }) =>
        name?.toLowerCase().includes(value?.toLowerCase()) &&
        name?.toLowerCase().startsWith(value?.toLowerCase())
    )

    if (indexValue === -1) {
      setOptions(filteredData)
    } else {
      const tempData = dataList
      const newListData = tempData.filter(val => val?.name !== value)
      const validData = filteredData.concat(newListData)
      setOptions(validData)
    }
  }, [value, dataList])

  useEffect(() => {
    document.addEventListener("click", function (e) {
      if (
        showList &&
        document.getElementById(props.id) &&
        !document.getElementById(props.id).contains(e.target)
      ) {
        setShowList(false)
      }
    })
  })

  const handleValueChange = event => {
    props.onChange(event.target.value)
    setShowList(true)
  }

  const keyDownAction = e => {
    const { KEYCODE } = CONSTANTS

    switch (e.keyCode) {
      case KEYCODE.DOWN:
        listRef.current = document.getElementById(`${props.id}-list-${0}`)
        document
          .getElementById(`${id}-list`)
          ?.scrollTo({ top: 0, behavior: "smooth" })
        listRef.current?.focus()
        break
      default:
        break
    }
  }

  return (
    <div className={styles.wrapper}>
      <div
        onClick={handleMouseDownEvent}
        onBlur={handleBlurEvent}
        className={`${dropDownClass} ${customClass ?? ""}`}
      >
        <div
          id={id}
          className={`select-dropdown__field ${
            showError ? "--with-error" : ""
          }`}
          onKeyDown={btnKeyDown}
          tabIndex={tabIndex ?? 0}
          role="combobox"
          aria-controls="dropdown-listbox"
          aria-expanded={showList}
          aria-haspopup="true"
          aria-label={
            label
              ? label.replace(/\*/g, "")
              : ariaLabel
              ? ariaLabel.replace(/\*/g, "")
              : ""
          }
          aria-required={ariaRequired ?? label.indexOf("*") > 0 ? true : false}
          aria-describedby={`${id}-selected`}
        >
          <div className={inputClasses}>
            <div className="select-dropdown__data-value">
              <input
                id={`input-${id}`}
                type={"text"}
                value={value}
                name={props.name}
                maxLength={props.maxLength}
                disabled={props.disabled}
                placeholder={props.label.replace(/\*/g, "")}
                onChange={handleValueChange}
                autoComplete="off"
                onKeyDown={keyDownAction}
              />
              <label htmlFor={`input-${id}`} aria-hidden="true">
                {label}
              </label>
            </div>
          </div>

          <div
            className={`select-dropdown__icon ${showList ? "--open" : ""}`}
            aria-hidden="true"
          >
            <img src="/icons/down-icon.svg" alt="open-icon" />
          </div>
        </div>

        <ul
          id={`${id}-list`}
          className="select-dropdown__dropdown"
          role="listbox"
          tabIndex="-1"
          aria-expanded="true"
          data-testid={"select-dropdown"}
        >
          {showList &&
            options?.map((item, i) => (
              <li
                ref={listRef}
                id={`${id}-list-${i}`}
                key={i}
                className={`select-dropdown__list-item ${
                  item?.name?.toLowerCase() === value?.toLowerCase() &&
                  "selected-item"
                }`}
                aria-selected={
                  item?.name?.toLowerCase() === value?.toLowerCase()
                }
                role="option"
                tabIndex="-1"
                onClick={() => handleSelect(item?.name)}
                onKeyDown={e => handleKeyDown(i, e)}
              >
                <span className="select-dropdown__list-value">
                  {item?.name}
                </span>
              </li>
            ))}
        </ul>
        {showError && errorMessage && (
          <span
            aria-live="polite"
            role="region"
            className="select-dropdown__error-message"
          >
            {errorMessage}
          </span>
        )}
      </div>
    </div>
  )
}

Autocomplete.defaultProps = {
  label: "",
}

export default Autocomplete
