import { useEffect, useState } from "react"
import DOMPurify from "isomorphic-dompurify"
import { staticClasses } from "@/constants"
import DynamicImage from "@/components/core/Image/DynamicImage"
import Link from "next/link"
import useSmartCrop from "@/hooks/useSmartCrop"
import { preventBodyScrollTab, removeAndCleanLinkConcat } from "@/utils/helper"
import useIsSsr from "@/hooks/useIsSsr"
import { useRouter } from "next/router"

const ProductsNavTab = props => {
  const {
    navOpen,
    setNavOpen,
    productNavigationItems,
    onClickNav,
    eventHandler: {
      store: { isOpen },
    },
    datalayerPageName,
    selectedTab,
    hideMobImages,
    showMobileImages,
  } = props
  const { locale, asPath } = useRouter()
  const routerAsPath = "/" + locale + asPath
  const [activeMainCategory, setActiveMainCategory] = useState("")
  const [activeSubCategory, setActiveSubCategory] = useState("")

  const isSsr = useIsSsr()
  const smartCrop = useSmartCrop()

  const onHoverMainCategory = name => {
    setActiveMainCategory(name)
  }

  const onHoverSubCategory = name => {
    setActiveSubCategory(name)
  }

  useEffect(() => {
    if (!isOpen || !navOpen) {
      setActiveSubCategory("")
      setActiveMainCategory("")
    }
  }, [isOpen, navOpen])

  useEffect(() => {
    if (productNavigationItems && activeMainCategory) {
      productNavigationItems.map((mainCategory, i) => {
        if (mainCategory?.categoryName === activeMainCategory) {
          const subCategoryDetails = mainCategory?.subCategoryDetails
          if (subCategoryDetails) {
            const childItem = subCategoryDetails?.childItems?.[0] || null
            if (childItem) {
              const activeSubCategory = childItem?.subCategory || null
              if (activeSubCategory) {
                setActiveSubCategory(activeSubCategory)
              }
            }
          }
        }
      })
    }
  }, [activeMainCategory, productNavigationItems])
  const IndiaSpecificNav = ["Bathroom", "Kitchen", "Combos"]
  return (
    <div
      role="tabpanel"
      tabIndex="-1"
      className={`cmp-tabs__tabpanel ${
        IndiaSpecificNav.includes(navOpen) ? "cmp-tabs__tabpanel--active" : ""
      }`}
    >
      <div className={`${staticClasses.CONTAINER}`}>
        <div className="cmp-container">
          <div className="aem-Grid aem-Grid--11 aem-Grid--default--11 ">
            <div className="globalnavigation aem-GridColumn aem-GridColumn--default--12">
              <div
                className={`gbh-product-menu ${
                  hideMobImages ? "hide-mobile-images" : ""
                } ${IndiaSpecificNav.includes(navOpen) ? "open" : ""}`}
              >
                <div className="gbh-pro-menu__inner">
                  <ul
                    className="flex-column gbh-pro-menu__list nav"
                    role="tablist"
                    aria-label="list of 6"
                    tabIndex="0"
                  >
                    <button
                      className="gbh-mob-back-btn"
                      onClick={() =>
                        onClickNav({
                          "cq:panelTitle": navOpen,
                        })
                      }
                    >
                      Menu
                    </button>
                    <p className="global-menu-selected">{navOpen}</p>
                    {productNavigationItems &&
                      productNavigationItems.map((item, index) => {
                        return (
                          <li
                            key={`productNavigation-${index}`}
                            className="nav-item"
                            data-tab={item?.categoryName}
                            role="tab"
                          >
                            <a
                              onMouseOver={() =>
                                onHoverMainCategory(
                                  !(
                                    !item?.categoryLinkRef && item?.categoryLink
                                  )
                                    ? item?.categoryName
                                    : ""
                                )
                              }
                              className={`gbh-data-layer nav-link ${
                                item?.categoryName === activeMainCategory
                                  ? "link-active"
                                  : ""
                              }`}
                              aria-hidden="false"
                              aria-label={item?.categoryName}
                              data-gbh-data-layer={`{"clickInternalLinks":"true","internalLinkPosition":"global header","internalLinkZoneName":"global header","internalLinkURL":"","internalLinkName":"products > ${item?.categoryName.toLowerCase()}","eventName":"main dropdown menu","internalLinkType":"global header:navigation","eventType":"global navigation","eventAction":"main dropdown menu"}`}
                              data-hover="tab"
                              data-target={`#submenu${item?.categoryName
                                .toLowerCase()
                                .split(" ")
                                .join("-")}`}
                              aria-controls={`submenu${item?.categoryName
                                .toLowerCase()
                                .split(" ")
                                .join("-")}`}
                              rel="noreferrer"
                              role="tab"
                              tabIndex="0"
                              href={
                                !item?.categoryLinkRef && item?.categoryLink
                                  ? removeAndCleanLinkConcat(item?.categoryLink)
                                  : null
                              }
                              aria-selected={
                                item?.categoryLinkRef ? "true" : ""
                              }
                              aria-expanded={
                                item?.categoryLinkRef ? "true" : ""
                              }
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(item?.categoryName),
                              }}
                            ></a>
                          </li>
                        )
                      })}
                  </ul>
                  <div className="tab-content gbh-pro-menu__cont">
                    {productNavigationItems &&
                      productNavigationItems.map((mainCategory, i) => {
                        return (
                          <div
                            key={`mainCategory-${i}`}
                            className={`tab-pane fade ${
                              mainCategory?.categoryName === activeMainCategory
                                ? "active show"
                                : ""
                            }`}
                            role="tabpanel"
                            aria-labelledby={mainCategory?.categoryName}
                          >
                            <div className="cmp-container">
                              <div className="aem-Grid aem-Grid--12 aem-Grid--default--12 ">
                                <div className="globalnavigation-subcategory aem-GridColumn aem-GridColumn--default--12">
                                  <ul className="nav gbh-pro-collection gbh-two-column">
                                    <button
                                      className="gbh-mob-back-btn"
                                      onClick={() => onHoverMainCategory("")}
                                    >
                                      Products
                                    </button>
                                    <p className="global-menu-selected sub-cat-select">
                                      {mainCategory?.categoryName}
                                    </p>
                                    {mainCategory?.subCategoryDetails &&
                                      mainCategory?.subCategoryDetails?.childItems?.map(
                                        (subCategory, j) => {
                                          return (
                                            <li
                                              key={`subCategory-${j}`}
                                              className="nav-item gbh-pro-collection__category-link"
                                              onMouseOver={() =>
                                                onHoverSubCategory(
                                                  subCategory?.subCategory
                                                )
                                              }
                                            >
                                              <a
                                                data-purelink="true"
                                                href={removeAndCleanLinkConcat(
                                                  subCategory?.subLink
                                                )}
                                                className={
                                                  "gbh-data-layer nav-link"
                                                }
                                                id={`pills-${mainCategory?.categoryName
                                                  .split(" ")
                                                  .join(
                                                    "-"
                                                  )}-${subCategory?.subCategory
                                                  .split(" ")
                                                  .join("-")}-tab`}
                                                aria-hidden="false"
                                                aria-label={`tab ${j} of ${mainCategory?.subCategoryDetails?.childItems.length} Collections`}
                                                data-hover="tab"
                                                tabIndex="0"
                                                target="_self"
                                                data-target={`#${subCategory?.subCategory
                                                  .split(" ")
                                                  .join(
                                                    "-"
                                                  )}${mainCategory?.categoryName
                                                  .toLowerCase()
                                                  .split(" ")
                                                  .join("-")}`}
                                                role="link"
                                                aria-controls={`pills-${subCategory?.subCategory
                                                  .split(" ")
                                                  .join("-")}`}
                                                data-gbh-data-layer={`{"clickInternalLinks":"true","internalLinkPosition":"global header","internalLinkZoneName":"global header","internalLinkURL":"${removeAndCleanLinkConcat(
                                                  subCategory?.subLink
                                                )}","internalLinkName":" ${selectedTab} > ${mainCategory?.categoryName
                                                  .toLowerCase()
                                                  .split(" ")
                                                  .join(
                                                    "-"
                                                  )} > ${subCategory?.subCategory
                                                  .split(" ")
                                                  .join(
                                                    "-"
                                                  )}","eventName":"main dropdown menu","internalLinkType":"global header:navigation","eventType":"global navigation","eventAction":"main dropdown menu"}`}
                                              >
                                                <span className="nav-link">
                                                  <span
                                                    dangerouslySetInnerHTML={{
                                                      __html:
                                                        DOMPurify.sanitize(
                                                          subCategory?.subCategory
                                                        ),
                                                    }}
                                                  />
                                                </span>
                                              </a>
                                            </li>
                                          )
                                        }
                                      )}
                                  </ul>
                                  <div className="tab-content gbh-pro-collection-cont gbh-two-column">
                                    {mainCategory?.subCategoryDetails &&
                                      mainCategory?.subCategoryDetails?.childItems?.map(
                                        (subCategory, j) => {
                                          return (
                                            <div
                                              key={`subcatInfo-${j}`}
                                              className={`tab-pane fade gbh-mob-col ${
                                                subCategory?.subCategory ===
                                                activeSubCategory
                                                  ? "active show"
                                                  : ""
                                              }`}
                                            >
                                              <a
                                                href={removeAndCleanLinkConcat(
                                                  subCategory?.subLink
                                                )}
                                                className="gbh-data-layer"
                                                data-gbh-data-layer={`{"clickInternalLinks":"true","internalLinkPosition":"global header","internalLinkZoneName":"global header","internalLinkURL":"${removeAndCleanLinkConcat(
                                                  subCategory?.subLink
                                                )}","internalLinkName":" > ${mainCategory?.categoryName
                                                  .toLowerCase()
                                                  .split(" ")
                                                  .join(
                                                    "-"
                                                  )} > ${subCategory?.subCategory
                                                  .split(" ")
                                                  .join(
                                                    "-"
                                                  )}","eventName":"main dropdown menu","internalLinkType":"global header:navigation","eventType":"global navigation","eventAction":"main dropdown menu"}`}
                                              >
                                                <span
                                                  className="hello gbh-subcattext"
                                                  dangerouslySetInnerHTML={{
                                                    __html: DOMPurify.sanitize(
                                                      subCategory?.subCategory
                                                    ),
                                                  }}
                                                />
                                                {showMobileImages && (
                                                  <div className="cmp-image">
                                                    {smartCrop.scriptStatus &&
                                                    !isSsr ? (
                                                      <DynamicImage
                                                        imgUrl={
                                                          subCategory?.sceneSevenImage
                                                        }
                                                        alt={
                                                          subCategory?.imageAlt
                                                        }
                                                        className={
                                                          "cmp-image__image with-crop"
                                                        }
                                                        uniqueId={`subcatImage-${j}`}
                                                      ></DynamicImage>
                                                    ) : (
                                                      <img
                                                        src={
                                                          subCategory?.sceneSevenImage
                                                        }
                                                        alt={
                                                          subCategory?.imageAlt
                                                        }
                                                        className="cmp-image__image without-crop"
                                                        key={`subcatImage-${j}`}
                                                      />
                                                    )}
                                                    <span
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          DOMPurify.sanitize(
                                                            subCategory?.description
                                                          ),
                                                      }}
                                                    />
                                                  </div>
                                                )}
                                              </a>
                                            </div>
                                          )
                                        }
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                  </div>
                  <span
                    className="gbh-promenu-close-btn icon-Close-Large"
                    aria-label="close"
                    role="button"
                    tabIndex="0"
                    onClick={() => {
                      setNavOpen("")
                      setActiveMainCategory("")
                      setActiveSubCategory()
                      preventBodyScrollTab(false)
                    }}
                  ></span>
                </div>
              </div>
              <div
                className={`productbg ${navOpen === "Products" ? "open" : ""}`}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductsNavTab
