import { useTranslation } from "next-i18next"
import parse from "html-react-parser"

const useProductEnquiry = authorData => {
  const { t } = useTranslation()
  const i18n = {
    mainTitle: authorData?.productEnquiryTitle
      ? parse(authorData?.productEnquiryTitle.replaceAll("&amp;nbsp;", " "))
      : t("kf.productEnquiry.main.title"),
    requiredInfoText: authorData?.productEnquiryFormDescription
      ? parse(
          authorData?.productEnquiryFormDescription.replaceAll(
            "&amp;nbsp;",
            " "
          )
        )
      : t("kf.customerSupport.label.requiredFields"),
    addMobileNumber: authorData?.productEnquiryText
      ? parse(authorData?.productEnquiryText.replaceAll("&amp;nbsp;", " "))
      : t("kf.productEnquiry.addMobileNumber"),
    enquiryTitle: t("kf.productEnquiry.main.enquiryTitle"),
    mobileNumber: t("kf.productEnquiry.mobileNumber"),
    alternateMobileNumber: t("kf.productEnquiry.alternateMobileNumber"),
    firstName: t("kf.productEnquiry.firstName"),
    lastName: t("kf.productEnquiry.lastName"),
    email: t("kf.dealershipEnquiry.emailAddress"),
    pinCode: t("kf.productEnquiry.pinCode"),
    firstNameError: t("kf.productEnquiry.error.firstName"),
    lastNameError: t("kf.productEnquiry.error.lastName"),
    errorPincode: t("kf.customerSupport.error.pincode"),
    errorSelectBox: t("kf.enquire.error.selectdropdown"),
    planToStartLabel: t("kf.enquire.select.plantostart"),
    commentsLabel: t("kf.enquire.comments"),
    planningLabel: t("kf.enquire.select.planning"),
    pincodeValid: t("kf.customerSupport.error.pincodeValid"),
    pincodeNotValid: t("kf.customerSupport.error.pincodeNotValid"),
    privacyText: authorData?.productEnquiryPrivacyPolicyText
      ? parse(
          authorData?.productEnquiryPrivacyPolicyText.replaceAll(
            "&amp;nbsp;",
            " "
          )
        )
      : t("kf.productEnquiry.privacyText"),
    submit: t("kf.customerSupport.label.submit"),
    cancel: t("kf.customerSupport.label.cancel"),
    messageDescriptionSuccess: authorData?.productEnquiryConfirmationText
      ? authorData?.productEnquiryConfirmationText
      : t("kf.productEnquiry.messageDescriptionSuccess"),
    backToHomePage: authorData?.productEnquiryConfirmationCtaLabel
      ? parse(
          authorData?.productEnquiryConfirmationCtaLabel.replaceAll(
            "&amp;nbsp;",
            " "
          )
        )
      : t("kf.productEnquiry.backToHomePage"),
    errorMobileNumber: t("kf.customerSupport.error.validMobileNumber"),
    emailId: t("kf.contactus.label.emailAddress"),
    validEmailId: t("kf.contactus.error.validEmail"),
    provideEmailError: t("kf.contactus.error.noEmailError"),
    verifyNumber: authorData?.productEnquiryMobilePlaceholderDescription
      ? parse(
          authorData?.productEnquiryMobilePlaceholderDescription.replaceAll(
            "&amp;nbsp;",
            " "
          )
        )
      : t("kf.customerSupport.message.verifyPhone"),
    numberIsVerified: authorData?.productEnquiryOtpDescription
      ? parse(
          authorData?.productEnquiryOtpDescription.replaceAll("&amp;nbsp;", " ")
        )
      : t("kf.customerSupport.mobileVerified"),
    productEnqBannerText: authorData?.productEnqBannerText ?? "",
  }

  return i18n
}

export default useProductEnquiry
