import React, { useEffect, useState } from "react"
import CountryUtilContainer from "@/components/Default/CountryUtilNav/v1/CountryUtilContainer"
import CountryUtilNavTabLink from "@/components/Default/CountryUtilNav/v1/CountryUtilNavTabLink"
import styles from "@/components/Default/CountryUtilNav/v1/CountryUtilNav.module.scss"
import useWindowDimensions from "@/hooks/useWindowDimensions"
const CountryUtilNav = props => {
  const {
    show,
    active,
    toggleCountryNav,
    eventHandler,
    countryselector,
    datalayerPageName,
  } = props

  const {
    callbacks: { setRegionNav = () => console.log("Event Not Defined") },
  } = eventHandler

  const { winWidth } = useWindowDimensions()
  const [activeTab, setActiveTab] = useState("")

  const addCountrySelectorAnalytics = type => {
    let eventInfo
    if (type === "Americas") {
      eventInfo = getAmericaTabEventInfo(type)
    } else if (type === "Africa") {
      eventInfo = getAfricaTabEventInfo(type)
    } else if (type === "Asia") {
      eventInfo = getAsiaTabEventInfo(type)
    } else if (type === "Australasia") {
      eventInfo = getAustralasiaTabEventInfo(type)
    } else if (type === "Middle East") {
      eventInfo = getMiddleEastTabEventInfo(type)
    } else if (type === "Europe") {
      eventInfo = getEuropeTabEventInfo(type)
    }
    const { adobeDataLayer: { getState } = {} } = window
    const page = (getState && getState("page")) || {}
    window.adobeDataLayer.push({
      event: "cmp:click",
      eventInfo,
      page,
    })
  }

  const getAmericaTabEventInfo = type => {
    if (type === "Americas") {
      return {
        clickInternalLinks: "true",
        eventAction: "country select:americas",
        eventName: "country select:americas",
        eventType: "navigation",
        internalLinkName: "americas",
        internalLinkPosition: "countryselector",
        internalLinkType: "global header : navigation",
        internalLinkZoneName: "global header",
        internalLinkURL: "n/a",
      }
    }
  }
  const getAfricaTabEventInfo = type => {
    if (type === "Africa") {
      return {
        clickInternalLinks: "true",
        eventAction: "country select:africa",
        eventName: "country select:africa",
        eventType: "navigation",
        internalLinkName: "africa",
        internalLinkPosition: "countryselector",
        internalLinkType: "global header : navigation",
        internalLinkZoneName: "global header",
        internalLinkURL: "n/a",
      }
    }
  }
  const getAsiaTabEventInfo = type => {
    if (type === "Asia") {
      return {
        clickInternalLinks: "true",
        eventAction: "country select:asia",
        eventName: "country select:asia",
        eventType: "navigation",
        internalLinkName: "asia",
        internalLinkPosition: "countryselector",
        internalLinkType: "global header : navigation",
        internalLinkZoneName: "global header",
        internalLinkURL: "n/a",
      }
    }
  }
  const getAustralasiaTabEventInfo = type => {
    if (type === "Australasia") {
      return {
        clickInternalLinks: "true",
        eventAction: "country select:australasia",
        eventName: "country select:australasia",
        eventType: "navigation",
        internalLinkName: "australasia",
        internalLinkPosition: "countryselector",
        internalLinkType: "global header : navigation",
        internalLinkZoneName: "global header",
        internalLinkURL: "n/a",
      }
    }
  }
  const getMiddleEastTabEventInfo = type => {
    if (type === "Middle East") {
      return {
        clickInternalLinks: "true",
        eventAction: "country select:middle east",
        eventName: "country select:middle east",
        eventType: "navigation",
        internalLinkName: "middle east",
        internalLinkPosition: "countryselector",
        internalLinkType: "global header : navigation",
        internalLinkZoneName: "global header",
        internalLinkURL: "n/a",
      }
    }
  }
  const getEuropeTabEventInfo = type => {
    if (type === "Europe") {
      return {
        clickInternalLinks: "true",
        eventAction: "country select:europe",
        eventName: "country select:europe",
        eventType: "navigation",
        internalLinkName: "europe",
        internalLinkPosition: "countryselector",
        internalLinkType: "global header : navigation",
        internalLinkZoneName: "global header",
        internalLinkURL: "n/a",
      }
    }
  }

  useEffect(() => {
    if (winWidth <= 1200) {
      setActiveTab("")
    } else {
      setActiveTab("america")
    }
  }, [winWidth])
  return (
    <div
      className={styles.countrySelectorMain}
      data-testid="countrySelectorSection"
    >
      <div
        id="gbh_utheader"
        className={`gbh-countryselector ${active ? "active" : ""}`}
        style={show ? { left: 0, display: "block" } : { display: "block" }}
      >
        <div className="gbh-tab">
          <span
            role="button"
            className="gbh-hamber-menu gbh-close icon-Close-Medium"
          ></span>
          <div
            role="button"
            tabIndex="0"
            data-testid="countrySelectorSection_menu"
            className="gbh-close-btn d-block d-lg-none pl-4 mb-2"
            onClick={() => {
              toggleCountryNav(), setRegionNav(false)
            }}
          >
            Menu
          </div>

          <ul role="tablist" aria-multiselectable="false" className="tablist">
            <div className="selection-bar"></div>
            <div onClick={() => addCountrySelectorAnalytics("Americas")}>
              <CountryUtilNavTabLink
                activeTab={activeTab}
                countryArea="america"
                countryAreaValue="Americas"
                onclickVal={setActiveTab}
              />
            </div>
            <div onClick={() => addCountrySelectorAnalytics("Africa")}>
              <CountryUtilNavTabLink
                activeTab={activeTab}
                countryArea="africa"
                countryAreaValue="Africa"
                onclickVal={setActiveTab}
              />
            </div>
            <div onClick={() => addCountrySelectorAnalytics("Asia")}>
              <CountryUtilNavTabLink
                activeTab={activeTab}
                countryArea="asia"
                countryAreaValue="Asia"
                onclickVal={setActiveTab}
              />
            </div>
            <div onClick={() => addCountrySelectorAnalytics("Australasia")}>
              <CountryUtilNavTabLink
                activeTab={activeTab}
                countryArea="australia"
                countryAreaValue="Australasia"
                onclickVal={setActiveTab}
              />
            </div>
            <div onClick={() => addCountrySelectorAnalytics("Middle East")}>
              <CountryUtilNavTabLink
                activeTab={activeTab}
                countryArea="middleEast"
                countryAreaValue="Middle East"
                onclickVal={setActiveTab}
              />
            </div>
            <div onClick={() => addCountrySelectorAnalytics("Europe")}>
              <CountryUtilNavTabLink
                activeTab={activeTab}
                countryArea="europe"
                countryAreaValue="Europe"
                onclickVal={setActiveTab}
              />
            </div>
          </ul>
        </div>
        <div
          className="gbh-tab__panels"
          style={winWidth <= 1200 && activeTab ? { display: "block" } : {}}
        >
          <span
            role="button"
            className="gbh-hamber-menu gbh-close icon-Close-Medium"
          ></span>
          <div
            role="button"
            tabIndex="0"
            className="gbh-back-btn d-block d-lg-none pl-4 mb-2"
            onClick={() => setActiveTab("")}
          >
            Region
          </div>
          <CountryUtilContainer
            countryarea="america"
            activeTab={activeTab}
            title={countryselector?.title}
            description={countryselector?.description}
            countryselectorItems={countryselector?.americasItems}
            datalayerPageName={datalayerPageName}
          ></CountryUtilContainer>
          <CountryUtilContainer
            countryarea="africa"
            activeTab={activeTab}
            title={countryselector?.title}
            description={countryselector?.description}
            countryselectorItems={countryselector?.africaItems}
            datalayerPageName={datalayerPageName}
          ></CountryUtilContainer>
          <CountryUtilContainer
            countryarea="asia"
            activeTab={activeTab}
            title={countryselector?.title}
            description={countryselector?.description}
            countryselectorItems={countryselector?.asiaItems}
            datalayerPageName={datalayerPageName}
          ></CountryUtilContainer>
          <CountryUtilContainer
            countryarea="australia"
            activeTab={activeTab}
            title={countryselector?.title}
            description={countryselector?.description}
            countryselectorItems={countryselector?.oceaniaItems}
            datalayerPageName={datalayerPageName}
          ></CountryUtilContainer>
          <CountryUtilContainer
            countryarea="middleEast"
            activeTab={activeTab}
            title={countryselector?.title}
            description={countryselector?.description}
            countryselectorItems={countryselector?.middleEastItems}
            datalayerPageName={datalayerPageName}
          ></CountryUtilContainer>
          <CountryUtilContainer
            countryarea="europe"
            activeTab={activeTab}
            title={countryselector?.title}
            description={countryselector?.description}
            countryselectorItems={countryselector?.europeItems}
            datalayerPageName={datalayerPageName}
          ></CountryUtilContainer>
        </div>
      </div>
    </div>
  )
}
export default CountryUtilNav
