import React from "react"

const NoStoreAvailable = props => {
  const { staticTexts, error } = props
  return (
    <>
      <div>
        <div className="find-a-store__horizontal-line" />
        <div className="find-a-store__no-store-text">
          {error ?? staticTexts?.selectDropdown}
        </div>
      </div>
    </>
  )
}

export default NoStoreAvailable
