import { useEffect, useCallback, useState, useRef } from "react"
import ProductsNavTab from "@/components/Shared/ProductsNavTab"
import { staticClasses } from "@/constants"
import { isMobile } from "react-device-detect"
import { useSelector } from "react-redux"
import { selectGenericState } from "@/store/features/genericSlice"
import {
  preventBodyScrollTab,
  removeAndCleanLinkConcat,
  getAnalyticLinkUrl,
} from "@/utils/helper"
import DOMPurify from "isomorphic-dompurify"

const HeaderNavTabs = props => {
  const {
    data,
    columnClassNames,
    eventHandler,
    eventHandler: {
      store: { isOpen },
      callbacks: {
        toggleMenu = () => console.log("Event Not Defined"),
        setMainCategory = () => console.log("Event Not Defined"),
      },
    },
  } = props

  const { pageIsInteractive, datalayerPageName } =
    useSelector(selectGenericState)

  const [productNavigationItems, setProductNavigationItems] = useState(null)
  const [hideMobImages, setHideMobImages] = useState(false)
  const [navOpen, setNavOpen] = useState("")

  const [selectedTab, setTab] = useState("")

  const menuVisible = useRef(0)
  const [showMobileImages, setShowMobileImages] = useState(true)
  const dataRecursion = useCallback(data => {
    if (data && data[":itemsOrder"]) {
      data[":itemsOrder"].map((key, index) => {
        const item = data[":items"][key]
        return dataRecursion(item)
      })
    }
  }, [])
  useEffect(() => {
    if (isMobile && hideMobImages) {
      setShowMobileImages(false)
    } else {
      setShowMobileImages(true)
    }
  }, [hideMobImages, isMobile])
  const onClickNav = data => {
    if (selectedTab !== data["cq:panelTitle"]) {
      const globalnavigation =
        data?.[":items"]?.globalnavigation ??
        data?.[":items"]?.globalnavigation_cop
      const { categoryItems, hideMobileImages } = globalnavigation
      setTab(data["cq:panelTitle"])
      setProductNavigationItems(categoryItems)
      setHideMobImages(hideMobileImages)
    }

    if (navOpen !== data["cq:panelTitle"]) {
      setNavOpen(data["cq:panelTitle"])
      if (!isMobile) {
        setNavOpen(data["cq:panelTitle"])
      }
      setMainCategory({ item: data["cq:panelTitle"] })
      menuVisible.current = 1
      preventBodyScrollTab(true)
      addHeaderNavTabAnalytics(data)
    } else {
      setNavOpen("")
      setTab("")
      setMainCategory({ item: "" })
      menuVisible.current = 0
      preventBodyScrollTab(false)
    }
  }

  const addHeaderNavTabAnalytics = data => {
    const eventInfo = {
      eventAction: `global header:${data["cq:panelTitle"].toLowerCase()}`,
      eventName: `global header:${data["cq:panelTitle"].toLowerCase()}`,
      eventType: "tab",
      internalLinkName: data["cq:panelTitle"].toLowerCase(),
      internalLinkPosition: "global header",
      internalLinkType: "global navigation",
      internalLinkZoneName: "global header",
      internalLinkURL: "n/a",
      clickInternalLinks: "true",
    }
    const { adobeDataLayer: { getState } = {} } = window
    const page = (getState && getState("page")) || {}
    window.adobeDataLayer.push({
      event: "cmp:click",
      page,
      eventInfo,
    })
  }

  const onMenuClickEvent = e => {
    if (
      (e?.target?.classList?.contains("gbh-pro-menu__inner") ||
        (e?.target?.closest(".gbh-pro-menu__inner") === null &&
          e?.target?.closest(".gbh-full-w-menu__inner") === null &&
          e?.target?.closest(".cmp-tabs__tablist") === null &&
          !isMobile)) &&
      menuVisible.current
    ) {
      setNavOpen("")
      setTab("")
      setMainCategory({ item: "" })
      menuVisible.current = 0
      preventBodyScrollTab(false)
    }
  }

  useEffect(() => {
    if (navOpen) {
      document.addEventListener("click", onMenuClickEvent)
    } else {
      document.removeEventListener("click", onMenuClickEvent)
    }
    return () => document.removeEventListener("click", onMenuClickEvent)
  }, [navOpen])

  useEffect(() => {
    dataRecursion(data)
  }, [data, dataRecursion])

  return (
    <div
      className={`tabs ${
        data?.appliedCssClassNames ? data?.appliedCssClassNames : ""
      } ${columnClassNames ? columnClassNames : ""} ${isOpen ? "open" : ""}`}
    >
      <div className="cmp-tabs">
        <span
          className="gbh-hamber-menu gbh-close icon-Close-Medium"
          aria-label="close button"
          tabIndex="0"
          onClick={() => {
            toggleMenu(),
              onClickNav({
                "cq:panelTitle": navOpen,
              })
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </span>

        <ol
          role="tablist"
          className="cmp-tabs__tablist"
          aria-multiselectable="false"
        >
          {data[":itemsOrder"] &&
            data[":itemsOrder"].map((key, index) => {
              const item = data[":items"][key]
              return (
                <li
                  role="tab"
                  className={`cmp-tabs__tab ${
                    selectedTab == item["cq:panelTitle"]
                      ? "cmp-tabs__tab--active"
                      : ""
                  }`}
                  tabIndex="0"
                  key={`navItem-${index}`}
                  onClick={() => onClickNav(item)}
                >
                  {item["cq:panelTitle"]}
                </li>
              )
            })}
          {data?.mainTabId &&
            Object.keys(data?.mainTabId).map((key, index) => {
              return (
                <li
                  role="tab"
                  className="cmp-tabs__tab"
                  tabIndex="0"
                  key={`navItem-${index}`}
                >
                  {key}
                </li>
              )
            })}
        </ol>
        {pageIsInteractive && (
          <ProductsNavTab
            productNavigationItems={productNavigationItems}
            navOpen={navOpen}
            setNavOpen={data => setNavOpen(data)}
            onClickNav={onClickNav}
            isOpen={isOpen}
            eventHandler={eventHandler}
            datalayerPageName={datalayerPageName}
            selectedTab={selectedTab}
            hideMobImages={hideMobImages}
            showMobileImages={showMobileImages}
          />
        )}
        {data[":itemsOrder"] &&
          data[":itemsOrder"].map((key, index) => {
            const items = data[":items"][key]
            const item = items[":items"]?.globalnavigation
            if (
              item?.mainNavigation === "drawer" &&
              navOpen === items["cq:panelTitle"]
            ) {
              return (
                <div
                  key={`navTabs-${index}`}
                  role="tabpanel"
                  tabIndex="-1"
                  className={`cmp-tabs__tabpanel ${
                    navOpen === items["cq:panelTitle"]
                      ? "cmp-tabs__tabpanel--active"
                      : ""
                  }`}
                >
                  <div className={`${staticClasses.CONTAINER}`}>
                    <div className="cmp-container">
                      <div className="aem-Grid aem-Grid--11 aem-Grid--default--11 ">
                        <div className="globalnavigation aem-GridColumn aem-GridColumn--default--11">
                          <div
                            className="gbh-full-w-menu"
                            style={{ transition: "all 2s ease 0s" }}
                          >
                            <div className="bg-dim">
                              <div className="gbh-full-w-menu__inner">
                                <div className="gbh-full-max-width">
                                  <div className="gbh-full-w-menu__left-cont">
                                    <div className="gbh-full-w-menu__left-cont__top">
                                      <div className="gbh-full-w-menu__left-cont__top__cont">
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(
                                              item.mainTitle
                                            ),
                                          }}
                                        />

                                        <div
                                          className="gbh-full-w-menu__left-cont__description"
                                          style={{
                                            transition: "none 0s ease 0s",
                                          }}
                                          dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(
                                              item.description
                                            ),
                                          }}
                                        />
                                      </div>
                                      <ul className="nav flex-column gbh-full-w-menu__left-cont__menu-list">
                                        <button
                                          className="gbh-mob-back-btn"
                                          onClick={() =>
                                            onClickNav({
                                              "cq:panelTitle": navOpen,
                                            })
                                          }
                                        >
                                          Menu
                                        </button>
                                        {item?.drawerItems?.map(
                                          (navItem, index) => {
                                            return (
                                              <li
                                                key={`navItem-${index}`}
                                                className="nav-item "
                                              >
                                                <a
                                                  className={`gbh-data-layer nav-link${
                                                    navItem?.subNavLinkRef
                                                      ? " extranal-sub-nav"
                                                      : ""
                                                  } `}
                                                  target={`${
                                                    navItem.newTab
                                                      ? "_blank"
                                                      : "_self"
                                                  }`}
                                                  href={removeAndCleanLinkConcat(
                                                    navItem?.subNavLink
                                                  )}
                                                  data-gbh-data-layer={`{"clickInternalLinks":"${
                                                    navItem?.subNavLinkRef
                                                      ? "false"
                                                      : "true"
                                                  }","internalLinkPosition":"global header","internalLinkZoneName":"global header","internalLinkURL":"${getAnalyticLinkUrl(
                                                    removeAndCleanLinkConcat(
                                                      navItem?.subNavLink
                                                    )
                                                  )}","internalLinkName":"${items[
                                                    "cq:panelTitle"
                                                  ]?.toLowerCase()} > ${navItem?.subNavName.toLowerCase()}","eventName":"main dropdown  menu","internalLinkType":"global header:navigation","eventType":"global navigation","eventAction":"main dropdown menu"}`}
                                                  rel={`${
                                                    navItem?.subNavLinkRef
                                                      ? "noreferrer"
                                                      : ""
                                                  }`}
                                                  data-hover="tab"
                                                  role="link"
                                                >
                                                  <span
                                                    dangerouslySetInnerHTML={{
                                                      __html:
                                                        DOMPurify.sanitize(
                                                          navItem.subNavName
                                                        ),
                                                    }}
                                                  />
                                                  {navItem?.subNavLinkRef && (
                                                    <div className="sub-cat-img">
                                                      {item?.externalIconCheckbox && (
                                                        <img
                                                          src={
                                                            item?.externalIcon
                                                          }
                                                          alt="external link"
                                                          href={
                                                            navItem.subNavLink
                                                          }
                                                        />
                                                      )}
                                                    </div>
                                                  )}
                                                </a>
                                              </li>
                                            )
                                          }
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="gbh-full-w-menu__right-img">
                                    {item?.drawerImage?.imageUrl && (
                                      <img
                                        src={item?.drawerImage?.imageUrl}
                                        className="cmp-image__image"
                                        alt={item?.drawerImage?.imageAlt}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="productbg" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          })}
      </div>
    </div>
  )
}

export default HeaderNavTabs
