import { pushAnalyticsObjToDataLayer } from "@/utils/helper"

const BrandSwitcher = props => {
  const { data, datalayerPageName } = props

  const handleClick = (e, item) => {
    const eventInfo = {
      clickInternalLinks: `${item.linkToNewTab ? "false" : "true"}`,
      internalLinkPosition: "global header",
      internalLinkZoneName: "global header",
      internalLinkURL: `${window.location.origin}${item.authoredLink
        ?.split(process.env.NEXT_PUBLIC_INTERNALLINKPATH)
        .join("")
        .replace(".html", "")}`,
      internalLinkName: `brand sync:${item.brandName.toLowerCase()}`,
      eventName: "brand sync",
      internalLinkType: "global header:navigation",
      eventType: "click",
      eventAction: "brand sync",
    }
    pushAnalyticsObjToDataLayer(eventInfo)
  }

  return (
    <div
      className={`brand-switcher ${
        datalayerPageName === "checkout" ? "checkout-brand" : ""
      }`}
    >
      <div className="gbh-nav-panel">
        <nav className="gbh-nav navbar navbar-xpand-sm navbar-dark justify-content-between">
          <ul className="gbh-nav__list navbar-nav">
            {data.items.map((item, index) => (
              <li className={`gbh-nav__item`} key={`brand-${index}`}>
                <a
                  className={`gbh-nav--link ${
                    item.highlight && item.highlight === "true"
                      ? "gbh-nav__link--active"
                      : ""
                  }`}
                  href={item.authoredLink
                    ?.split(process.env.NEXT_PUBLIC_INTERNALLINKPATH)
                    .join("")
                    .replace(".html", "")}
                  aria-label={`${item.brandName}. Go to ${item.brandName}'s Home Page`}
                  target={`${item.linkToNewTab ? "_blank" : "_self"}`}
                  rel={`${item.linkRef ? "noreferrer" : ""}`}
                  onClick={e => handleClick(e, item)}
                >
                  {item.brandName}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default BrandSwitcher
