import Button from "@/components/core/Button/Button"
import Input from "@/components/core/Input/Input"
import styles from "@/components/Shared/Otp/v1/index.module.scss"
import { useEffect, useState, useRef } from "react"
import { PHONE_REGEX } from "@/constants/index"
import { useSelector } from "react-redux"
import { validNumber } from "@/utils/validate"
import Modal from "@/components/core/Modal/Modal"
import { activateUserFactor, checkNumberExists } from "@/utils/enquiry"
import { selectAuthState } from "@/store/features/authSlice"
import useAuthModali18n from "@/i18n/useAuthModali18n"
import {
  verifyOtpAnalytics,
  changeNumberAnalytics,
  resendOtpAnalytics,
  closeOtpAnalytics,
  getOtpAnalytics,
  wantToLeaveAnalytics,
  confirmCloseAnalytics,
  privacyStatementAnalytics,
} from "@/components/Shared/Otp/v1/analytics"

const OtpFields = props => {
  const {
    handleClose,
    mobile,
    showModal,
    verifiedNumber,
    changeMobileNumber,
    userData,
    setData = null,
    setDataEnquiry = null,
    userDetails: userFactor,
    page = "",
  } = props
  const { authModal: { staticText } = {} } = useSelector(selectAuthState)
  const i18n = useAuthModali18n(staticText)
  const [changeNumber, setChangeNumber] = useState(changeMobileNumber)
  const [mobileNumber, setMobileNumber] = useState(mobile)
  const [showMobileNumberError, setShowMobileNumberError] = useState({
    show: false,
    message: "",
  })
  const [loading, setLoading] = useState(false)
  const otpDigits = [0, 1, 2, 3, 4, 5]
  const [otpEnteredByUser, setOtpEnteredByUser] = useState([])
  const [isVerifyDisabled, setIsVerifyDisabled] = useState(true)
  const [numberOfTries, setNumberOfTries] = useState(0)
  const [timer, setTimer] = useState(5) // 5 minutes
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)
  const [showResendOTP, setShowResendOTP] = useState(false)
  const [showTimer, setShowTimer] = useState(false)
  const [reachedLimit, setReachedLimit] = useState(false)
  const [intervalId, setIntervalId] = useState(null)
  const [verifyOTPLoading, setVerifyOTPLoading] = useState(null)
  const [invalidOTP, setInvalidOTP] = useState(null)
  const DIGIT_REGEX = /[0-9]/
  const [timeLeftToResend, setTimeLeftToResend] = useState(null)
  const mobileNumberRef = useRef(mobileNumber)
  const [userDetails, setUserDetails] = useState(userFactor)
  const [reachedInvalidAttemptsLimit, setReachedInvalidAttemptsLimit] =
    useState(0)
  const [confirmationModal, setConfirmationModal] = useState(false)
  const [exceedCountTimer, setExceedCountTimer] = useState(false)
  useEffect(() => {
    if (localStorage.getItem("timeLeftEnquiryForm")) {
      setTimeLeftToResend(localStorage.getItem("timeLeftEnquiryForm"))
      const now = new Date().getTime()
      const timeLimit = new Date(
        parseInt(now) + parseInt(localStorage.getItem("timeLeftEnquiryForm"))
      )
      const interval = setInterval(
        () => resendTimerFunc(interval, timeLimit),
        1000
      )
    }
  }, [])
  useEffect(() => {
    if (!changeNumber) {
      const now = new Date().getTime()
      const timeLimit = new Date(now + timer * 60000)
      const interval = setInterval(() => timerFunc(interval, timeLimit), 1000)
      setShowTimer(true)
      setIntervalId(interval)
    }
  }, [changeNumber])
  const getOTP = async event => {
    event?.preventDefault()
    clearError()
    const payload = {
      login: mobileNumber,
    }
    const loginPhoneNumber = localStorage.getItem("loginPhoneNumberEnquiry")
    const timeLeft = localStorage.getItem("timeLeftEnquiryForm")
    let valid = true
    if (!mobileNumber || !PHONE_REGEX.test(mobileNumber)) {
      setShowMobileNumberError({
        show: true,
        message: i18n?.errorPhoneNumber,
      })
      valid = false
    }
    if (loginPhoneNumber && loginPhoneNumber === mobileNumber && timeLeft > 0) {
      valid = false
      if (reachedLimit) setReachedLimit(true)
      setShowResendOTP(false)
      setChangeNumber(false)
      setReachedInvalidAttemptsLimit(true)
      setShowTimer(false)
    }
    if (loginPhoneNumber !== mobileNumber) {
      localStorage.setItem("loginPhoneNumberEnquiry", mobileNumber)
      localStorage.removeItem("timeLeftEnquiryForm")
    }
    if (valid) {
      setLoading(true)
      if (changeNumber) mobileNumberRef.current.disabled = true
      let { numberExists, factorId, userId } = await checkNumberExists(
        mobileNumber
      )
      if (!numberExists) {
        setUserDetails({ userId, factorId })
        const now = new Date().getTime()
        const timeLimit = new Date(now + timer * 60000)
        const interval = setInterval(() => timerFunc(interval, timeLimit), 1000)
        setShowTimer(true)
        setIntervalId(interval)
        setChangeNumber(false)
        setLoading(false)
        setShowResendOTP(false)
        setReachedInvalidAttemptsLimit(false)
      } else {
        setLoading(false)
        setShowResendOTP(true)
        setLoading(false)
        setShowTimer(false)
        setUserDetails({})
        setChangeNumber(false)
      }
    }
  }
  useEffect(() => {
    if (timeLeftToResend < 0) {
      setMinutes(0)
      setSeconds(0)
      setShowTimer(false)
      setNumberOfTries(0)
      setShowResendOTP(false)
      setReachedLimit(false)
      setReachedInvalidAttemptsLimit(true)
      localStorage.removeItem("timeLeftEnquiryForm")
    }
  }, [timeLeftToResend])
  const timerFunc = (interval, timeLimit) => {
    const currentTime = new Date().getTime()
    const timeleft = timeLimit - currentTime
    // Calculating the minutes and seconds left
    let minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60))
    let seconds = Math.floor((timeleft % (1000 * 60)) / 1000)
    if (minutes < 10) {
      minutes = "0" + minutes
    }
    if (seconds < 10) {
      seconds = "0" + seconds
    }
    setMinutes(minutes)
    setSeconds(seconds)
    if (timeleft < 0) {
      clearInterval(interval)
      setMinutes(0)
      setSeconds(0)
      setShowTimer(false)
      if (numberOfTries < 3) {
        setShowResendOTP(true)
      }
      setReachedLimit(false)
    }
  }

  const resendTimerFunc = (interval, timeLimit) => {
    const currentTime = new Date().getTime()
    const timeleft = timeLimit - currentTime
    localStorage.setItem("timeLeftEnquiryForm", timeleft)
    setTimeLeftToResend(timeleft)
    if (timeleft < 0) {
      clearInterval(interval)
    }
  }

  const isDisabled = mobileNumber === "" || !PHONE_REGEX.test(mobileNumber)

  const verifyOTP = async event => {
    event?.preventDefault()
    const filteredArray = otpEnteredByUser.filter(otp => {
      return otp === undefined || otp === ""
    })

    const isOTPVerifyDisabled =
      otpEnteredByUser.length !== 6 || filteredArray.length > 0
    if (!isOTPVerifyDisabled) {
      const payload = {
        data: { passCode: otpEnteredByUser.join("") },
        userId: userDetails?.userId,
        factorId: userDetails?.factorId,
      }
      setVerifyOTPLoading(true)
      const { data, status } = await activateUserFactor(payload)
      if (status === 403 && data?.errorCode === "E0000068") {
        setInvalidOTP(true)
        setVerifyOTPLoading(false)
        verifyOtpAnalytics(page, "failure", "otp verification failed")
      } else if (status === 200) {
        setVerifyOTPLoading(false)
        verifiedNumber()
        setReachedInvalidAttemptsLimit(false)
        setData?.({
          ...userData,
          phone: { ...userData?.phone, value: mobileNumber },
        })
        verifyOtpAnalytics(page, "success")
        setDataEnquiry?.(mobileNumber)
      } else if (status === 429) {
        setVerifyOTPLoading(false)
        setShowTimer(false)
        setInvalidOTP(false)
        setIsVerifyDisabled(true)
        clearInterval(intervalId)
        const now = new Date().getTime()
        const time = 5
        const timeLimit = new Date(now + time * 60000)
        const interval = setInterval(
          () => resendTimerFunc(interval, timeLimit),
          1000
        )
        setTimeout(() => {
          setExceedCountTimer(false)
        }, 5 * 60 * 1000)
        setExceedCountTimer(true)
        setShowResendOTP(false)
        setIntervalId(interval)
        setReachedInvalidAttemptsLimit(true)
        verifyOtpAnalytics(
          page,
          "failure",
          "otp verification failed by too many attempts"
        )
      } else if (status === 404) {
        setInvalidOTP(true)
        setVerifyOTPLoading(false)
        verifyOtpAnalytics(page, "failure", "otp verification failed")
      }
    }
  }

  const clearError = () => {
    setShowMobileNumberError({ show: false, message: "" })
  }

  const addOTPDigit = (event, index) => {
    const otpEntered = [...otpEnteredByUser]
    const value = event.target.value
    if (value === "") {
      otpEntered[index] = ""
      if (index > 0)
        document.getElementById(`kf-signin-country-code-${index - 1}`).focus()
      setInvalidOTP(false)
    } else if (DIGIT_REGEX.test(value)) {
      otpEntered[index] = value
      if (index < 5)
        document.getElementById(`kf-signin-country-code-${index + 1}`).focus()
    } else {
      otpEntered[index] = ""
    }
    setOtpEnteredByUser(otpEntered)
    const filteredArray = otpEntered.filter(otp => {
      return otp === undefined || otp === ""
    })
    const isOTPVerifyDisabled =
      otpEntered.length !== 6 || filteredArray.length > 0 || loading
    setIsVerifyDisabled(isOTPVerifyDisabled)
  }

  const inputChangeFocus = event => {
    event?.preventDefault()
    const filteredArray = otpEnteredByUser.filter(otp => {
      return otp === undefined || otp === ""
    })
    const isOTPVerifyDisabled =
      otpEnteredByUser.length !== 6 || filteredArray.length > 0 || loading
    setIsVerifyDisabled(isOTPVerifyDisabled)
  }

  const resendOTP = async event => {
    event?.preventDefault()
    clearInterval(intervalId)
    if (numberOfTries === 3) {
      setReachedLimit(true)
      setTimer(15) //15 mins
      setShowTimer(false)
      setShowResendOTP(false)
      setOtpEnteredByUser([])
      setInvalidOTP(false)
      setIsVerifyDisabled(true)
      const now = new Date().getTime()
      const time = 15
      const timeLimit = new Date(now + time * 60000)
      const interval = setInterval(
        () => resendTimerFunc(interval, timeLimit),
        1000
      )
      setIntervalId(interval)
    } else {
      setNumberOfTries(numberOfTries + 1)
      setShowResendOTP(false)
      setOtpEnteredByUser([])
      const filteredArray = otpEnteredByUser.filter(otp => {
        return otp === undefined || otp === ""
      })
      const isOTPVerifyDisabled =
        otpEnteredByUser.length !== 6 || filteredArray.length > 0 || loading
      setIsVerifyDisabled(isOTPVerifyDisabled)
      setReachedInvalidAttemptsLimit(false)
      setTimer(5)
      setShowTimer(true)
      setOtpEnteredByUser([])
      setInvalidOTP(false)
      const payload = {
        login: mobileNumber,
      }
      let { numberExists, factorId, userId } = await checkNumberExists(
        mobileNumber
      )
      if (!numberExists) {
        setUserDetails({ userId, factorId })
        setChangeNumber(false)
        setIsVerifyDisabled(true)
        setReachedLimit(false)
        const time = 5 // 5 mins
        setTimer(5) // 5mins
        const now = new Date().getTime()
        const timeLimit = new Date(now + time * 60000)
        const interval = setInterval(() => timerFunc(interval, timeLimit), 1000)
        setIntervalId(interval)
      } else {
        setLoading(false)
        setShowResendOTP(true)
        setLoading(false)
        setShowTimer(false)
        setUserDetails({})
        setChangeNumber(false)
      }
    }
    resendOtpAnalytics(page)
  }

  const backToSignIn = event => {
    if (!loading) {
      event?.preventDefault()
      setNumberOfTries(0)
      setShowMobileNumberError({ show: false, message: "" })
      setMobileNumber("")
      setOtpEnteredByUser([])
      setMinutes(0)
      setSeconds(0)
      setReachedLimit(false)
      setShowResendOTP(false)
      setInvalidOTP(false)
      clearInterval(intervalId)
      setTimer(5)
      setChangeNumber(true)
      changeNumberAnalytics(page)
    }
  }
  const handleChangeNumber = event => {
    const { value } = event.target
    if (value === "" || validNumber(value)) {
      setMobileNumber(value)
    }
  }

  const onBlur = () => {
    if (!mobileNumber || !PHONE_REGEX.test(mobileNumber)) {
      setShowMobileNumberError({
        show: true,
        message: i18n?.errorPhoneNumber,
      })
    } else {
      setShowMobileNumberError({
        show: false,
        message: "",
      })
    }
  }

  const handleCloseConfirmation = () => {
    setConfirmationModal(true)
    closeOtpAnalytics(page)
  }

  const handleOnPaste = event => {
    const otpEntered = event.clipboardData.getData("text")
    const otpArray = otpEntered.split("")
    setOtpEnteredByUser(otpArray)
    const filteredArray = otpArray.filter(otp => {
      return otp === undefined || otp === ""
    })
    const isOTPVerifyDisabled =
      otpEntered.length !== 6 || filteredArray.length > 0 || loading
    setIsVerifyDisabled(isOTPVerifyDisabled)
  }

  const handleGetOtp = e => {
    getOTP(e)
    getOtpAnalytics(page)
  }

  const handleWantToLeave = choice => {
    if (choice === "yes") {
      setConfirmationModal(false)
      handleClose()
    } else {
      setConfirmationModal(false)
    }
    wantToLeaveAnalytics(page, choice)
  }

  useEffect(() => {
    document.getElementById(`kf-signin-country-code-0`) &&
      document.getElementById(`kf-signin-country-code-0`).focus()
  }, [document.getElementById(`kf-signin-country-code-0`)])

  const termsRef = useRef(null)

  const addTermsLinkAnalytics = () => {
    const links = termsRef.current.querySelectorAll("a")
    Array.from(links).forEach(link => {
      link.addEventListener("click", () =>
        privacyStatementAnalytics(
          page,
          link.innerText?.toLowerCase(),
          link.getAttribute("href")
        )
      )
    })
  }

  useEffect(() => {
    // to add analytics for terms and policy links
    if (showModal) {
      setTimeout(addTermsLinkAnalytics, 100)
    }
  }, [showModal])

  return (
    <>
      <Modal
        showModal={showModal}
        onModalClose={handleCloseConfirmation}
        labelledBy="kf-signup-modal"
        hideCloseIcon={false}
        authModal={false}
      >
        <div className={styles.otpVerification}>
          {/* class name otp-verification is specific for customer support */}
          {!changeNumber && (
            <div className="otp-popup">
              <>
                <div className="otp-popup__title">{i18n?.otpTitle}</div>
                <div className="otp-popup__description">
                  <div className="otp-popup__mobileprint">
                    {i18n.label}
                    {` +91 ${mobileNumber} `}
                  </div>
                  <span
                    className="otp-change-number"
                    onClick={backToSignIn}
                    style={
                      loading
                        ? { cursor: "auto" }
                        : { cursor: "pointer", textDecorationLine: "underline" }
                    }
                  >
                    {i18n.changeNumber}
                  </span>
                </div>
                <form>
                  {showTimer && (
                    <div className="otp-popup__timerDiv">
                      <span className="expireotp">{i18n?.otpexpire}</span>
                      <span className="auth-otp-modal__timer">
                        {`${minutes}:${seconds}`}
                      </span>
                    </div>
                  )}

                  {showResendOTP && (
                    <div className="otp-popup__otpSendTextDiv">
                      <span className="otp-popup__otpSendText">
                        {i18n.otpNotReceived}
                      </span>
                      <a
                        target="_blank"
                        className="otp-popup__updateNumber"
                        rel="noreferrer"
                        onClick={resendOTP}
                      >
                        {i18n.resendOTP}
                      </a>
                    </div>
                  )}

                  {reachedInvalidAttemptsLimit ? (
                    <div className="otp-popup__otpSendTextDiv">
                      <span className="otp-popup__otpSendText">
                        {i18n.tryagainmin}{" "}
                      </span>
                      <a
                        target="_blank"
                        className={
                          exceedCountTimer
                            ? "resend-disabled"
                            : "otp-popup__updateNumber"
                        }
                        rel="noreferrer"
                        onClick={resendOTP}
                      >
                        {i18n.resendOTP}
                      </a>
                    </div>
                  ) : null}

                  <div className="otp-popup__otpDigitsDiv">
                    {otpDigits.map(otp => (
                      <div className="otp-popup__otpDigit" key={otp}>
                        <Input
                          id={`kf-signin-country-code-${otp}`}
                          type="tel"
                          name={`countryCode-${otp}`}
                          value={otpEnteredByUser[otp] ?? ""}
                          onChange={event => addOTPDigit(event, otp)}
                          maxLength="1"
                          onFocus={inputChangeFocus}
                          onKeyDown={e => {
                            if (
                              (e.key === "Delete" || e.key === "Backspace") &&
                              e.target.value === ""
                            ) {
                              if (otp > 0) {
                                document
                                  .getElementById(
                                    `kf-signin-country-code-${otp - 1}`
                                  )
                                  .focus()
                              }
                            }

                            const filteredArray = otpEnteredByUser.filter(
                              otp => {
                                return otp === undefined || otp === ""
                              }
                            )
                            const isOTPVerifyDisabled =
                              otpEnteredByUser.length !== 6 ||
                              filteredArray.length > 0
                            if (e.key === "Enter") {
                              if (!isOTPVerifyDisabled) {
                                e.preventDefault()
                                verifyOTP()
                              } else {
                                e.preventDefault()
                              }
                            }
                          }}
                          showError={invalidOTP}
                          disabled={reachedLimit || reachedInvalidAttemptsLimit}
                          pattern="\d*"
                          onPaste={handleOnPaste}
                        />
                      </div>
                    ))}
                  </div>

                  {invalidOTP &&
                    !showResendOTP &&
                    otpEnteredByUser.length > 0 && (
                      <div className="otp-popup__otpErrorDiv ">
                        {/* OTP is not valid. Please enter a valid OTP to continue. */}
                        {i18n?.errorOTP}
                      </div>
                    )}

                  {showResendOTP && otpEnteredByUser.length > 0 && (
                    <div className="otp-popup__otpErrorDiv ">
                      {/* OTP is invalid or no longer active. */}
                      {i18n.invalidOTP}
                    </div>
                  )}
                  {reachedInvalidAttemptsLimit ? (
                    <div className="otp-popup__otpErrorDiv ">
                      {/* You have exceeded limit for OTP attempts. */}
                      {i18n.exceedlimit}
                    </div>
                  ) : null}
                  {/* {reachedLimit === true ? (
                    <div className="otp-popup__timerDiv">{i18n.tryAgain}</div>
                  ) : null} */}
                  <div className="otp-popup__otp-popup-button">
                    <Button
                      role="button"
                      className="gbh-data-layer"
                      label={i18n.verifySignIn}
                      type="black"
                      flexible={true}
                      onClick={verifyOTP}
                      loading={verifyOTPLoading}
                      disabled={isVerifyDisabled || reachedInvalidAttemptsLimit}
                      customClass="otp-popup__button"
                    />
                  </div>
                  <div className="otp-popup__footer" ref={termsRef}>
                    <span>{i18n.policyText}</span>
                  </div>
                </form>
              </>
            </div>
          )}{" "}
          {changeNumber && (
            <div className="change-number-popup">
              <div className="change-number-popup__title">
                {i18n?.changeMobileTitle}
              </div>
              <div className="change-number-popup__description">
                {i18n.changeMobileDescription}
              </div>
              <form>
                <div className="change-number-popup__phoneDiv">
                  <div className="change-number-popup__countryIcon">
                    <Input
                      type="text"
                      name="countryCode"
                      value={"+91"}
                      disabled
                    />
                  </div>
                  <div className="change-number-popup__phoneNumber">
                    <Input
                      id="kf-signin-mobile-number"
                      type="text"
                      name="mobileNumber"
                      value={mobileNumber}
                      ref={mobileNumberRef}
                      ariaRequried={true}
                      placeholder={i18n.enterMobileNumber}
                      label={i18n.enterMobileNumber}
                      onChange={handleChangeNumber}
                      onKeyDown={e => {
                        e.key === "Enter" && handleGetOtp(e)
                      }}
                      showError={showMobileNumberError.show}
                      errorMessage={showMobileNumberError.message}
                      autoFocus={false}
                      maxLength="10"
                      onBlur={onBlur}
                    />
                  </div>
                </div>

                <div className="change-number-popup__wrap-button">
                  <Button
                    role="button"
                    className="gbh-data-layer"
                    label={i18n.getOTP}
                    type="black"
                    flexible={true}
                    onClick={handleGetOtp}
                    loading={loading}
                    disabled={isDisabled}
                    customClass="change-number-popup__button"
                  />
                </div>
                <div className="change-number-popup__footer" ref={termsRef}>
                  <span>{i18n.policyText}</span>
                </div>
              </form>
            </div>
          )}
        </div>
      </Modal>
      <Modal
        showModal={confirmationModal}
        onModalClose={() => {
          setConfirmationModal(false)
          confirmCloseAnalytics(page)
        }}
        className="confirmation"
      >
        <div
          className="otp-popup__modal"
          aria-modal
          role="dialog"
          tabIndex="-1"
        >
          <div className="otp-popup__title">{i18n.exitConfirmation}</div>
          <div className="otp-popup__buttonDiv">
            <Button
              className="otp-popup__button"
              type="black"
              role="button"
              label={"Yes"}
              onClick={() => handleWantToLeave("yes")}
            />
            <Button
              className="otp-popup__button"
              type="ghost"
              role="button"
              label={"No"}
              onClick={() => handleWantToLeave("no")}
            />
          </div>
        </div>
      </Modal>
    </>
  )
}

export default OtpFields
