import { apiBaseUrl, apimSubscriptionKey } from "@/constants/index"
import {
  findAStoreUrl,
  getDetailsClient,
  getLocationFromIPURL,
  getStoresServer,
} from "@/utils/constants"
import { getAccessToken } from "@/utils/helper"
import { aemAxiosInstance, apim } from "@/constants/api"
import { getStateWithCitiesServer } from "./constants"

export const getStateAndCities = async () => {
  let accessToken = null
  accessToken = getAccessToken()?.token
  return new Promise(async (resolve, reject) => {
    try {
      const { data: content } = await apim.get(
        `${apiBaseUrl}${findAStoreUrl}/states-with-cities`
      )
      resolve(content)
    } catch (error) {
      reject(error)
    }
  })
}

export const getStoresList = async (storeState, storeCity) => {
  let accessToken = null
  accessToken = getAccessToken()?.token
  return new Promise(async (resolve, reject) => {
    try {
      const { data: content } = await apim.get(
        `${apiBaseUrl}${findAStoreUrl}?brand=india&state=${storeState}&city=${storeCity}&page=1&limit=100`
      )
      resolve(content)
    } catch (error) {
      reject(error)
    }
  })
}

export const getDetailsFromZipCode = async zipcode => {
  let accessToken = null
  accessToken = getAccessToken()?.token
  return new Promise(async (resolve, reject) => {
    try {
      const { data: content } = await apim.get(
        `${apiBaseUrl}${getDetailsClient}?pincode=${zipcode}`
      )
      resolve(content)
    } catch (error) {
      resolve(error.response?.status)
    }
  })
}

export const getDetailsFromPincode = async zipcode => {
  return new Promise(async (resolve, reject) => {
    try {
      aemAxiosInstance.defaults.headers.common[
        "Ocp-Apim-Subscription-Key"
      ] = `${apimSubscriptionKey}`
      const { data: content } = await aemAxiosInstance({
        url: `${apiBaseUrl}${getDetailsClient}?pincode=${zipcode}`,
      })
      resolve(content)
    } catch (error) {
      resolve(error.response.status)
    }
  })
}

export const getStoresFromDetails = async (storeState, storeCity) => {
  return new Promise(async (resolve, reject) => {
    try {
      aemAxiosInstance.defaults.headers.common[
        "Ocp-Apim-Subscription-Key"
      ] = `${apimSubscriptionKey}`
      const { data: content } = await aemAxiosInstance({
        url: `${apiBaseUrl}${getStoresServer}?brand=india&state=${storeState}&city=${storeCity}&page=1&limit=100`,
      })
      resolve(content)
    } catch (error) {
      reject(error)
    }
  })
}

export const getStatesAndCitiesFromServer = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      aemAxiosInstance.defaults.headers.common[
        "Ocp-Apim-Subscription-Key"
      ] = `${apimSubscriptionKey}`
      const { data: content } = await aemAxiosInstance({
        url: `${apiBaseUrl}${getStateWithCitiesServer}`,
      })
      resolve(content)
    } catch (error) {
      reject(error)
    }
  })
}

export const getLocationFromIP = async ip => {
  let accessToken = null
  accessToken = getAccessToken()?.token
  return new Promise(async (resolve, reject) => {
    try {
      const { data: content } = await apim.get(
        `${apiBaseUrl}${getLocationFromIPURL}/${ip}`
      )
      resolve(content)
    } catch (error) {
      reject(error)
    }
  })
}
export const getDealerNames = async (limit = "") => {
  let accessToken = null
  accessToken = getAccessToken()?.token
  return new Promise(async (resolve, reject) => {
    try {
      const { data: content } = await apim.get(
        `${apiBaseUrl}${findAStoreUrl}?brand=india&limit=${limit}`
      )
      resolve(content)
    } catch (error) {
      reject(error)
    }
  })
}
