import { useTranslation } from "next-i18next"

const useAuthUtilityNavi18n = () => {
  const { t } = useTranslation()
  const i18n = {
    signIn: t("Sign In"),
    createAccount: t("Create Account"),
    hi: t("kf.auth.label.hi"),
    title: t("kf.auth.label.bannerMssg"),
    welcome: t("kf.auth.label.welcome"),
    logout: t("kf.auth.label.logout"),
    rightArrowAlt: t("kf.auth.label.rightArrowAlt"),
    leftArrowAlt: t("kf.auth.label.rightArrowAlt"),
    menu: t("kf.auth.label.menu"),
    expanded: t("kf.auth.label.expanded"),
    collapsed: t("kf.auth.label.collapsed"),
    user: t("kf.auth.label.user"),
  }

  return i18n
}

export default useAuthUtilityNavi18n
