import { SPECIAL_CHAR_REGEX } from "@/constants/regex"
import { pushAnalyticsObjToDataLayer, removeAndCleanLink } from "@/utils/helper"

export const getEventInfo = (
  pageType = "",
  componentPosition = "",
  mainTitle = "",
  eventLabel = "",
  dataLayerLinkType = "",
  eventType = "",
  zone = "",
  parseJson = false
) => {
  const zoneName = zone ? zone?.replace(SPECIAL_CHAR_REGEX, " ") : "zone"
  let internalLinkType = ""
  if (eventLabel === "print") {
    internalLinkType = `${pageType.toLowerCase()}:${mainTitle.toLowerCase()}:print`
  } else {
    internalLinkType = `${dataLayerLinkType}`
  }

  const internalLinkZoneName = zone
    ? `${pageType.toLowerCase()}:${zoneName}`
    : "teaser"

  const eventInfo = {
    clickInternalLinks: "true",
    internalLinkPosition: `${pageType.toLowerCase()}:${
      componentPosition ? componentPosition : "teaser"
    }`,
    internalLinkZoneName,
    internalLinkURL: "n/a",
    internalLinkName: `${eventLabel}`,
    eventName: `${pageType.toLowerCase()}:${
      eventLabel === "print" ? eventLabel : `${zoneName}:share`
    }`,
    internalLinkType: internalLinkType,
    eventType: eventType,
    eventAction: `${pageType.toLowerCase()}:${
      eventLabel === "print" ? eventLabel : `${zoneName}:share`
    }`,
  }
  if (parseJson) {
    return eventInfo
  }
  return JSON.stringify(eventInfo)
}

export const addDatalayerShare = (data = "{}") => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const eventInfo = JSON.parse(data)
  window.adobeDataLayer.push({
    event: "cmp:click",
    eventInfo,
    page,
  })
}

export const getDownloadEventInfo = (
  downLoadLink = "",
  pageType = "",
  componentPosition = "",
  mainTitle = "",
  dataLayerLinkType = ""
) => {
  let eventInfo = {}
  if (downLoadLink) {
    eventInfo = {
      clickInternalLinks: "true",
      internalLinkPosition: `${pageType.toLowerCase()}:${
        componentPosition ? componentPosition : "teaser"
      }`,
      internalLinkZoneName: "teaser",
      internalLinkURL: `${downLoadLink}`,
      internalLinkName: "download",
      eventName: `${pageType.toLowerCase()}:download`,
      internalLinkType: `${pageType.toLowerCase()}:${mainTitle.toLowerCase()}:download`,
      eventType: "teaser",
      eventAction: `${pageType.toLowerCase()}:download`,
    }
  } else {
    eventInfo = {
      clickInternalLinks: "true",
      internalLinkPosition: `${pageType.toLowerCase()}:${
        componentPosition || "teaser"
      }`,
      internalLinkZoneName: "teaser",
      internalLinkURL: "n/a",
      internalLinkName: "download",
      eventName: `${pageType.toLowerCase()}:download`,
      internalLinkType: `${dataLayerLinkType}`,
      eventType: "teaser",
      eventAction: `${pageType.toLowerCase()}:download`,
    }
  }

  return JSON.stringify(eventInfo)
}

export const getTitleEventInfo = (
  linkNewTab = false,
  componentPosition = "",
  linkURL = "",
  mainTitle = "",
  pageType = "",
  componentLinkType = ""
) => {
  return `{"clickInternalLinks":"${
    linkNewTab ? "false" : "true"
  }","internalLinkPosition":"${
    componentPosition ? componentPosition : "teaser"
  }","internalLinkZoneName":"teaser","internalLinkURL":"${linkURL}","internalLinkName":"${mainTitle.toLowerCase()}","eventName":"${pageType.toLowerCase()}:zone:${mainTitle.toLowerCase()}","internalLinkType":"${pageType}:${
    componentLinkType ? componentLinkType : mainTitle.toLowerCase()
  }","eventType":"teaser","eventAction":"${pageType.toLowerCase()}:zone:${mainTitle.toLowerCase()}"}`
}

export const addTeaserActionAnalytics = ({
  newTab,
  pageType,
  componentPosition,
  videoTitle,
  link,
  text,
  eventName: eventNameDatalayer,
  dataLayerLinkType,
  eventAction: eventActionDataLayer,
  productCategory,
  productCollectionsName,
  productRoom,
  datalayerPageType,
  compInfo,
  mainTitle,
}) => {
  let eventAction = `${pageType?.toLowerCase()}:${
    eventActionDataLayer?.toLowerCase() || "zone"
  }:${text?.toLowerCase()}`
  let eventName = `${pageType?.toLowerCase()}:${
    eventActionDataLayer?.toLowerCase() || "zone"
  }:${text?.toLowerCase()}`
  let internalLinkZoneName = `${
    videoTitle || pageType.toLowerCase() === "plp"
      ? `${pageType.toLowerCase()}:hero banner`
      : eventActionDataLayer
      ? `${pageType?.toLowerCase()}:${eventActionDataLayer?.toLowerCase()}`
      : `teaser`
  }`
  if (datalayerPageType.includes("locations")) {
    eventName = `${pageType?.toLowerCase()}:${text?.toLowerCase()}`
    eventAction = `${pageType?.toLowerCase()}:${text?.toLowerCase()}`
    internalLinkZoneName = pageType?.toLowerCase()
  }

  const eventInfo = {
    clickInternalLinks: newTab ? "true" : "false",
    internalLinkPosition: componentPosition
      ? `${pageType?.toLowerCase()}:${componentPosition}`
      : "teaser",
    internalLinkZoneName,
    internalLinkURL: link
      ? `${
          link.indexOf("http") === -1 ? window.location.origin : ""
        }${removeAndCleanLink(link)}`
      : "n/a",
    internalLinkName: `${!text ? "explore more arrow" : text.toLowerCase()}`,
    eventName,
    internalLinkType: `${
      videoTitle
        ? `${pageType.toLowerCase()}:${videoTitle}`
        : dataLayerLinkType?.replace(/&amp;/g, "&")
    }`,
    eventType: "teaser",
    eventAction,
    eventMsg: "n/a",
    eventStatus: "n/a",
  }

  if (pageType.includes("experience centre")) {
    eventInfo.internalLinkType = `find a store:${mainTitle}`
  }

  if (!text) {
    eventInfo.clickInternalLinks = "true"
    eventInfo.eventAction = `${pageType.toLowerCase()}:hero banner:explore more arrow`
    eventInfo.eventName = `${pageType.toLowerCase()}:hero banner:explore more arrow`
    eventInfo.internalLinkType = `${pageType.toLowerCase()}:navigation`
    eventInfo.internalLinkZoneName = `${pageType.toLowerCase()}:hero banner`
  }

  let componentInfoData = compInfo && JSON.parse(compInfo).componentInfo
  const customDataLayer = componentInfoData
    ? {
        componentInfo: componentInfoData,
        productInfo: {
          productCategory: productCategory || "n/a",
          productCollectionsName: productCollectionsName || "n/a",
          productRoom: productRoom || "n/a",
        },
      }
    : {
        productInfo: {
          productCategory: productCategory || "n/a",
          productCollectionsName: productCollectionsName || "n/a",
          productRoom: productRoom || "n/a",
        },
      }

  pushAnalyticsObjToDataLayer(eventInfo, "cmp:click", {}, customDataLayer)
}
