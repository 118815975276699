import { useEffect, useState } from "react"
import Button from "@/components/core/Button/Button"
import editIcon from "@/public/icons/edit.svg"
import Input from "@/components/core/Input/Input"
import { validNumber, validText, validateEmail } from "@/utils/validate"
import { setBannerConfirmationText, validateField } from "@/utils/helper"
import { PHONE_REGEX } from "@/constants/index"
import { checkNumberExists, submitProductEnquiry } from "@/utils/enquiry"
import OtpFields from "@/components/Shared/Otp/v1/index"
import { useDispatch, useSelector } from "react-redux"
import {
  selectAuthState,
  setAuthModalVisibility,
} from "@/store/features/authSlice"
import useAuthModali18n from "@/i18n/useAuthModali18n"
import { useRouter } from "next/router"
import { isMobile, isTablet } from "react-device-detect"
import {
  getOtpAnalytics,
  submitEnquiryAnalytics,
  cancelEnquiryAnalytics,
} from "@/components/ProductEnquiry/analytics"
import _get from "lodash/get"
import { isBrandTransact } from "@/utils/index"

const ProductEnquiry = props => {
  const { staticText, setConfirmationSuccess } = props
  const {
    authModal: { staticTextPopup } = {},
    isAuth,
    user,
  } = useSelector(selectAuthState)
  const dispatch = useDispatch()
  const i18n = useAuthModali18n(staticTextPopup)
  const [verifiedMobileNumber, setVerifiedMobileNumber] = useState(false)
  const [error, setError] = useState({})
  const [verifyOTP, setVerifyOTP] = useState(false)
  const [changeNumber, setChangeNumber] = useState(false)
  const [userDetails, setUserDetails] = useState(false)
  const router = useRouter()
  const [data, setData] = useState({})

  useEffect(() => {
    if (isAuth) {
      setVerifiedMobileNumber(true)
      let dataInitialValue = {
        firstName: _get(user, "firstName", ""),
        phone: _get(user, "login", ""),
        lastName: _get(user, "lastName", ""),
        email: _get(user, "sub", ""),
        pincode: "",
        alternativePhone: "",
      }
      setData(dataInitialValue)
    }
  }, [isAuth, verifiedMobileNumber])
  const onNameChange = e => {
    const { name, value } = e.target
    if (value === "" || validText(value)) {
      setData({
        ...data,
        [name]: value,
      })
    }
  }
  const onChangeAlternatePhone = e => {
    const { value } = e.target

    if (value === "" || validNumber(value)) {
      setData({
        ...data,
        alternativePhone: value,
      })
    }
  }
  const onChangePincode = e => {
    const { value } = e.target
    if (value === "" || validNumber(value)) {
      setData({
        ...data,
        pincode: value,
      })
    }
    if (!value?.trim())
      setError({
        ...error,
        pincode: staticText?.errorPincode,
      })
    else if (value?.length !== 6)
      setError({
        ...error,
        pincode: staticText?.pincodeValid,
      })
  }
  const onBlur = field => {
    setError({
      ...error,
      [field]: validateExist(field),
    })
  }

  const isValidNumber = PHONE_REGEX.test(data?.phone)

  const validateExist = field => {
    if (field === "firstName" || field === "lastName") {
      if (!data[field]?.trim() || !validateField("name", data[field])) {
        return field === "firstName"
          ? staticText?.firstNameError
          : staticText?.lastNameError
      } else return ""
    } else if (field === "pincode") {
      if (!data?.pincode?.trim()) return staticText?.errorPincode
      else if (data?.pincode?.length !== 6) return staticText?.pincodeValid
    } else if (field === "phone") {
      if (!data?.phone?.trim() || !PHONE_REGEX.test(data?.phone)) {
        return staticText?.errorMobileNumber
      }
    } else if (field === "emailId") {
      if (!data[field]) return ""
      else if (
        !validateField("email", data[field]) ||
        !validateEmail(data[field])
      )
        return staticText?.validEmailId
    }
  }
  const handleSubmit = async () => {
    const errorList = getErrorList()
    const { sku, pname, id, ...rest } = router.query
    let errorDataList = []
    setError(errorList)
    if (errorList?.firstName) {
      errorDataList.push(`firstName`)
    } else if (errorList?.lastName) {
      errorDataList.push(`lastName`)
    } else if (errorList?.pincode) {
      errorDataList.push(`pincode`)
    }
    errorDataList.map((item, i) => {
      if (i === 0) document.getElementById(`${item}`)?.focus()
    })
    const isInvalid = Object?.values(errorList)?.find(el => el)
    if (!isInvalid) {
      const { firstName, lastName, pincode, phone, email, alternativePhone } =
        data
      const payload = {
        firstName: firstName?.trim(),
        pincode: pincode,
        mobile: phone,
        lastName: lastName?.trim(),
        enquiryType: "product-enquiry",
        sku: sku ?? "",
        inRiver: id ?? "",
        productName: pname ? `${pname}&${Object.keys(rest).join()}` : "",
        email: email ?? "",
        atlernateNumber: alternativePhone,
      }
      const utmParams = [
        "utm_source",
        "utm_content",
        "utm_medium",
        "utm_term",
        "utm_campaign",
      ]
      utmParams.forEach(param => {
        if (rest?.[param]) {
          payload[param] = decodeURIComponent(rest[param])
        }
      })
      const response = await submitProductEnquiry(payload)
      if (response.statusCode === 200) {
        submitEnquiryAnalytics("success")
        setConfirmationSuccess(true)
        setBannerConfirmationText(
          staticText.productEnqBannerText,
          "enquiry-form-banner"
        )
        scrollToTop()
      } else {
        submitEnquiryAnalytics("failure", "form submission failed")
        setConfirmationSuccess(false)
        scrollToTop()
      }
    } else {
      submitEnquiryAnalytics("failure", "form validation failed")
    }
  }
  const handleResetData = () => {
    if (isAuth) {
      setData({ ...data, alternativePhone: "", pincode: "" })
    } else {
      setData({})
    }

    setError({})
    setVerifiedMobileNumber(false)
    scrollToTop()
    cancelEnquiryAnalytics()
  }

  const getErrorList = () => {
    const errorList = {
      firstName: validateExist("firstName"),
      lastName: validateExist("lastName"),
      pincode: validateExist("pincode"),
      phoneNumber: validateExist("phone"),
      emailId: validateExist("emailId"),
    }
    return errorList
  }

  const scrollToTop = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    })
  }

  const isEnabled =
    Object?.values(getErrorList())?.find(el => el) || !verifiedMobileNumber
      ? true
      : false
  const changeNumberHandler = async () => {
    setVerifyOTP(true)
    setChangeNumber(true)
  }
  const verifiedNumber = () => {
    setVerifiedMobileNumber(true)
    setVerifyOTP(false)
  }
  const getOTP = async () => {
    let { numberExists, factorId, userId } = await checkNumberExists(
      data?.phone
    )
    const isBrandOrTransact = isBrandTransact()
    if (!isBrandOrTransact && numberExists && !isAuth) {
      dispatch(setAuthModalVisibility({ show: true }))
    } else {
      if (isValidNumber) {
        setUserDetails({ factorId: factorId, userId: userId })
        setVerifyOTP(true)
        setChangeNumber(false)
      } else {
        setVerifyOTP(false)
      }
    }
    getOtpAnalytics()
  }
  const handleChangeNumber = event => {
    const { value } = event.target
    if (value === "" || validNumber(value)) {
      setData({
        ...data,
        phone: value,
      })
      setError({
        ...error,
        phone: "",
      })
    }
  }
  const onChangeEmailId = e => {
    const { value } = e.target
    setData({
      ...data,
      email: value,
    })

    if (value) {
      if (validateEmail(value)) {
        setError({
          ...error,
          email: "",
        })
      } else {
        setError({
          ...error,
          email: staticText.validEmailId,
        })
      }
    }
  }

  const setPhoneNumber = phone => {
    setData({
      ...data,
      phone: phone,
    })
  }

  return (
    <div className="product-enquiry__margin-div">
      {!verifiedMobileNumber && (
        <div className="product-enquiry__phoneDiv">
          <div className="product-enquiry__countryIcon">
            <Input
              customClass="product-enquiry__countryField"
              id="contact-user-country-code"
              type="text"
              disabled
              value={"+91"}
            />
          </div>
          <div className="product-enquiry__phoneNumber">
            <Input
              disabled={verifiedMobileNumber}
              customClass="product-enquiry__inputfield"
              id="contact-user-phone"
              type="text"
              value={data.phone ?? ""}
              maxLength={10}
              placeholder={staticText?.mobileNumber}
              label={staticText?.mobileNumber}
              onChange={handleChangeNumber}
              showError={error?.phone}
              errorMessage={error?.phone}
              onBlur={() => onBlur("phone")}
            />
          </div>
          <button
            onClick={getOTP}
            style={
              isValidNumber ? { cursor: "pointer" } : { cursor: "not-allowed" }
            }
            className={`product-enquiry__getOTPLink ${
              isValidNumber && "--active"
            }`}
          >
            {i18n?.getOTP}
          </button>
        </div>
      )}
      {verifiedMobileNumber && (
        <div>
          <Input
            customClass="product-enquiry__phoneDivDisabled"
            type="text"
            disabled
            value={`+91 ${data?.phone}`}
            placeholder={staticText?.mobileNumber}
            label={staticText?.mobileNumber}
            suffix={
              verifiedMobileNumber && (
                <img
                  src={editIcon?.src}
                  alt=""
                  aria-hidden="true"
                  // onClick={!isAuth && changeNumberHandler}
                  style={{ cursor: "pointer" }}
                />
              )
            }
          />
        </div>
      )}
      <div
        className={`${
          !verifiedMobileNumber && isValidNumber
            ? "product-enquiry__verifyNumberText --error"
            : "product-enquiry__verifyNumberText"
        }`}
      >
        {!verifiedMobileNumber
          ? staticText?.verifyNumber
          : staticText?.numberIsVerified}
      </div>
      <div className="row mx-0">
        <div className="col-12 col-sm-6 col-md-6 pr-10 pl-0">
          <Input
            id="contact-user-fname"
            type="text"
            customClass="customer-support-inputfield"
            value={data?.firstName ?? ""}
            maxLength={40}
            placeholder={staticText?.firstName}
            label={staticText?.firstName}
            onChange={onNameChange}
            showError={error?.firstName}
            errorMessage={error?.firstName}
            onBlur={() => onBlur("firstName")}
            name="firstName"
            autoComplete={false}
          />
        </div>
        <div className="col-12 col-sm-6 col-md-6 pl-10 pr-0">
          <Input
            id="contact-user-lname"
            type="text"
            customClass="customer-support-inputfield"
            value={data?.lastName ?? ""}
            maxLength={40}
            placeholder={staticText?.lastName}
            label={staticText?.lastName}
            onChange={onNameChange}
            showError={error?.lastName}
            errorMessage={error?.lastName}
            onBlur={() => onBlur("lastName")}
            name="lastName"
            autoComplete={false}
          />
        </div>
      </div>
      <Input
        id="contact-user-emailId"
        type="text"
        placeholder={staticText?.email}
        label={staticText?.email}
        value={data?.email ?? ""}
        onChange={onChangeEmailId}
        showError={error?.emailId}
        errorMessage={error?.emailId}
        maxLength={60}
        onBlur={() => onBlur("emailId")}
        autoComplete={false}
      />
      <div className="product-enquiry__phoneDiv">
        <div className="product-enquiry__countryIcon">
          <Input
            customClass="product-enquiry__countryField"
            id="contact-user-country-code"
            type="text"
            disabled
            value={"+91"}
          />
        </div>
        <div className="product-enquiry__phoneNumberAlternate">
          <Input
            customClass="product-enquiry__inputfield"
            type="text"
            value={data?.alternativePhone}
            maxLength={10}
            placeholder={staticText?.alternateMobileNumber}
            label={staticText?.alternateMobileNumber}
            onChange={onChangeAlternatePhone}
          />
        </div>
      </div>
      <Input
        id="contact-user-pincode"
        type="text"
        placeholder={staticText?.pinCode}
        label={staticText?.pinCode}
        value={data?.pincode ?? ""}
        maxLength={6}
        autoComplete={false}
        onChange={onChangePincode}
        showError={error?.pincode}
        errorMessage={error?.pincode}
        onBlur={() => onBlur("pincode")}
      />
      <div className="product-enquiry__privacyText">
        {staticText?.privacyText}
      </div>
      <div className="product-enquiry__buttonDiv">
        <Button
          type="black"
          role="button"
          label={staticText.submit}
          className="product-enquiry__button"
          flexible
          onClick={handleSubmit}
          disabled={isEnabled}
        />
        <Button
          className="product-enquiry__button"
          type="ghost"
          role="button"
          label={staticText.cancel}
          flexible
          onClick={handleResetData}
        />
      </div>
      {verifyOTP ? (
        <OtpFields
          page="place an enquiry"
          handleClose={() => setVerifyOTP(false)}
          showModal={verifyOTP}
          staticTexts={staticText}
          mobile={data?.phone}
          verifiedNumber={verifiedNumber}
          changeMobileNumber={changeNumber}
          setDataEnquiry={phoneNumber => setPhoneNumber(phoneNumber)}
          userDetails={userDetails}
        />
      ) : null}
    </div>
  )
}

export default ProductEnquiry
