import {
  getShortenedUrlwithGeneral,
  sanitizeInnerHtml,
  sanitizeTextArticle,
} from "@/utils/helper"
import { ConditionalWrapper } from "@/utils/index"
import _isEmpty from "lodash/isEmpty"
import { useEffect, useState } from "react"

const Description = props => {
  const {
    linkURL = "",
    linkURLRef = "",
    linkNewTab = "",
    description = "",
    mainTitle = "",
    componentPosition = null,
    componentLinkType = null,
    pageType = "",
    linkDescription = false,
    general = {},
  } = props

  const [shortenedLinkUrl, setShortenedLinkUrl] = useState("")
  const [target, setTarget] = useState("")
  const [details, setDetails] = useState(description)
  useEffect(() => {
    if (!_isEmpty(general)) {
      if (linkURL) {
        setShortenedLinkUrl(getShortenedUrlwithGeneral(linkURL, general))
      }
      if (description) {
        setDetails(getShortenedUrlwithGeneral(description, general))
      }
    }
  }, [linkURL, description, general])

  // Added to fix eslint error Disallow target="_blank" attribute without rel="noreferrer" (react/jsx-no-target-blank)
  useEffect(() => {
    if (linkNewTab) {
      setTarget("_blank")
    } else {
      setTarget("_self")
    }
  }, [linkNewTab])

  return (
    <div
      className="cmp-teaser__description"
      data-gbh-data-layer={`{"clickInternalLinks":"${
        linkNewTab ? "false" : "true"
      }","internalLinkPosition":"${pageType.toLowerCase()}:${
        componentPosition ? componentPosition : "teaser"
      }","internalLinkZoneName": "${
        mainTitle ? `${pageType.toLowerCase()}:${mainTitle}` : "teaser"
      }","internalLinkURL":"${linkURL}","internalLinkName":"${sanitizeTextArticle(
        description.toLowerCase()
      )}","eventName":"${pageType.toLowerCase()}:${mainTitle}:rm","internalLinkType":"${pageType}:${
        componentLinkType ? componentLinkType : mainTitle.toLowerCase()
      }","eventType":"teaser","eventAction":"${pageType.toLowerCase()}:${mainTitle}:rm"}`}
    >
      <ConditionalWrapper
        condition={linkDescription}
        wrapper={children => (
          <a
            href={shortenedLinkUrl}
            rel={linkURLRef ? "noreferrer" : ""}
            target={target}
          >
            {children}
          </a>
        )}
      >
        <div>{sanitizeInnerHtml(details)}</div>
      </ConditionalWrapper>
    </div>
  )
}

export default Description
