import React from "react"
import styles from "@/components/ArticleCard/index.module.scss"
import MissingImage from "@/public/images/image-missing.png"

export function ArticleCard({ data, eyebrowText }) {
  const { title_s = "", thumbNailImage_s, url_s } = data

  return (
    <div className={styles.articleCard}>
      <div className="article-card-page__details">
        <div className="article-card-page__eyebrow">{eyebrowText}</div>
        <div className="article-card-page__title">{title_s}</div>
        <a href={url_s} className="article-card-page__cta">
          <span className="ctaText">{"Read More"}</span>
        </a>
      </div>
      <div className="article-card-page__article-image">
        <img
          src={thumbNailImage_s ?? MissingImage.src}
          alt={title_s}
          onError={e => (e.target.src = MissingImage?.src)}
        />
      </div>
    </div>
  )
}
