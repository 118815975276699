import {
  pushAnalyticsObjToDataLayer,
  removeAndCleanLinkConcat,
} from "@/utils/helper"

const pageType = "place an enquiry"
const componentType = "product enquiry"

export const breadcrumbAnalytics = navItem => {
  const link = navItem?.url
    ? (navItem.url.indexOf("http") === -1 ? window.location.origin : "") +
        removeAndCleanLinkConcat(navItem.url)
    : "n/a"
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${pageType}:hero banner:breadcrumb`,
    eventName: `${pageType}:hero banner:breadcrumb`,
    eventType: "navigation",
    internalLinkName: "home",
    internalLinkPosition: pageType,
    internalLinkType: `${pageType}:breadcrumb`,
    internalLinkZoneName: `${pageType}:hero banner`,
    internalLinkURL: link,
  }
  pushAnalyticsObjToDataLayer(eventInfo)
}

export const getOtpAnalytics = () => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${pageType}:${componentType}:form:get otp`,
    eventName: `${pageType}:${componentType}:form:get otp`,
    eventType: "navigation",
    internalLinkName: "get otp",
    internalLinkPosition: pageType,
    internalLinkType: `${pageType}:navigation`,
    internalLinkZoneName: `${pageType}:${componentType}:form`,
    internalLinkURL: "n/a",
  }
  pushAnalyticsObjToDataLayer(eventInfo)
}

export const editMobileAnalytics = () => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${pageType}:${componentType}:form:mobile number:edit`,
    eventName: `${pageType}:${componentType}:form:mobile number:edit`,
    eventType: "navigation",
    internalLinkName: "edit",
    internalLinkPosition: pageType,
    internalLinkType: `${pageType}:${componentType}`,
    internalLinkZoneName: `${pageType}:${componentType}:form:mobile number`,
    internalLinkURL: "n/a",
  }
  pushAnalyticsObjToDataLayer(eventInfo)
}

export const submitEnquiryAnalytics = (status, statusMsg) => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${pageType}:${componentType}:form:submit`,
    eventName: `${pageType}:${componentType}:form:submit`,
    eventStatus: status,
    eventMsg: statusMsg ?? "n/a",
    eventType: "navigation",
    internalLinkName: "submit",
    internalLinkPosition: pageType,
    internalLinkType: `${pageType}:navigation`,
    internalLinkZoneName: `${pageType}:${componentType}:form`,
    internalLinkURL: "n/a",
  }
  pushAnalyticsObjToDataLayer(eventInfo)
}

export const cancelEnquiryAnalytics = () => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${pageType}:${componentType}:form:cancel`,
    eventName: `${pageType}:${componentType}:form:cancel`,
    eventType: "navigation",
    internalLinkName: "cancel",
    internalLinkPosition: pageType,
    internalLinkType: `${pageType}:navigation`,
    internalLinkZoneName: `${pageType}:${componentType}:form`,
    internalLinkURL: "n/a",
  }
  pushAnalyticsObjToDataLayer(eventInfo)
}

export const backToHomeAnalytics = link => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${pageType}:${componentType}:back to homepage`,
    eventName: `${pageType}:${componentType}:back to homepage`,
    eventType: "navigation",
    internalLinkName: "back to homepage",
    internalLinkPosition: pageType,
    internalLinkType: `${pageType}:navigation`,
    internalLinkZoneName: `${pageType}:${componentType}`,
    internalLinkURL: link,
  }
  pushAnalyticsObjToDataLayer(eventInfo)
}
