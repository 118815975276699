import React from "react"
import { useSelector } from "react-redux"
import dynamic from "next/dynamic"
import { selectGenericState } from "@/store/features/genericSlice"
import { COMPONENT_TYPES } from "@/constants/index"
import MainFooter from "@/components/Shared/MainFooter"
import CollapsibleFooter from "@/components/Shared/CollapsibleFooter"
import NewsletterFooter from "@/components/Shared/NewsletterFooter/NewsletterFooter"
import AdditionalFooter from "@/components/Shared/AdditionalFooter"
import Text from "@/components/Default/Text/v1/Text"

const SitewideWarning = dynamic(
  () => import("@/components/SitewideWarning/v1/SitewideWarning"),
  {
    ssr: false,
  }
)

const Footer = props => {
  const { data } = props
  const { siteWideWarning } = useSelector(selectGenericState)

  return (
    <>
      {data[":itemsOrder"].map((key, index) => {
        const item = data[":items"][key]
        if (key === "accordion") {
          return (
            <React.Fragment key={`${key}-${index}`}>
              <CollapsibleFooter
                data={item}
                key={`${key}-${index}`}
                columnClassNames={`${key} ${data.columnClassNames[key]}`}
              />
            </React.Fragment>
          )
        }
        if (key === "mainfooter") {
          return (
            <React.Fragment key={`${key}-${index}`}>
              <MainFooter
                data={item}
                key={`${key}-${index}`}
                columnClassNames={`${key} ${data.columnClassNames[key]}`}
              />
              {siteWideWarning?.content && (
                <SitewideWarning
                  key={`${key}-${index}-SitewideWarning`}
                  isRendered={siteWideWarning.isRendered}
                  siteWideWarning={siteWideWarning?.content}
                />
              )}
            </React.Fragment>
          )
        }

        if (key === "newslettersignin") {
          return (
            <NewsletterFooter
              data={item}
              key={`${key}-${index}`}
              columnClassNames={`${key} ${data.columnClassNames[key]}`}
            />
          )
        }
        if (key.includes("additionalfooter")) {
          return (
            <AdditionalFooter
              data={item}
              key={`${key}-${index}`}
              columnClassNames={`${data[":items"][key]["appliedCssClassNames"]} ${key} ${data.columnClassNames[key]}`}
            />
          )
        }
        if (item[":type"]?.includes(COMPONENT_TYPES.TEXT)) {
          return (
            <Text
              data={item}
              key={`${key}-${index}`}
              columnClassNames={`${data[":items"][key]["appliedCssClassNames"]} ${key} ${data.columnClassNames[key]}`}
            />
          )
        }
      })}
    </>
  )
}

export default Footer
