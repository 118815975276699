import { useState, useEffect } from "react"
import axios from "axios"
import styles from "@/components/FindAStore/index.module.scss"
import StoreListing from "@/components/FindAStore/StoreListing"
import NoStoreAvailable from "@/components/FindAStore/NoStoreAvailable"
import {
  getDetailsFromZipCode,
  getStoresList,
  getLocationFromIP,
} from "utils/findStore"
import Autocomplete from "@/components/core/Autocomplete/Autocomplete"
import useFindAStore from "@/i18n/useFindAStore"
import {
  backToTopAnalytics,
  citySelectionAnalytics,
  stateSelectionAnalytics,
} from "./analytics"
import useIsSsr from "@/hooks/useIsSsr"

const FindAStore = props => {
  const { stateWithCities } = props
  const staticTexts = useFindAStore()
  const [stateChange, setStateChange] = useState("")
  const [cityChange, setCityChange] = useState("")
  const [listData, setListData] = useState(null)
  const [cityList, setCityList] = useState([])
  const [errorMessage, setErrorMessage] = useState(null)
  const [storeList, setServerList] = useState(null)
  const [backToTopButton, setBackToTopButton] = useState(false)

  const isSsr = useIsSsr()
  useEffect(() => {
    if (isSsr) return
    setListData(stateWithCities)
    getLocation()
    backToTop()
  }, [isSsr])

  const getLocation = async () => {
    const response = await axios.get(`https://api.ipify.org/?format=json`)
    const ip = response?.data?.ip ?? ""
    const ipRegex = /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/

    if(ip && ipRegex.test(ip)) {
      const locationDetails = await getLocationFromIP(ip)
      const zipcode = locationDetails?.data?.Z
      let details = { state: "", city: "" }
      let result
      let storeList = []
      const resp = await getDetailsFromZipCode(zipcode)
      if (resp !== 400) {
        details = {
          state: resp?.result?.data?.state
            ? resp?.result?.data?.state === "Tamil Nadu"
              ? "Tamilnadu"
              : resp?.result?.data?.state
            : "",
          city: resp?.result?.data?.city ?? "",
        }
        result = await getStoresList(details?.state, details?.city)
        storeList = result.result.data.stores ?? []
        setStateChange(details.state)
        setCityChange(details.city)
        if (storeList.length === 0) {
          const error = staticTexts.notInRegion
          setErrorMessage(error)
        } else {
          setStateChange(details.state)
          setCityChange(details.city)
          setServerList(storeList)
        }
      } else {
        const error = staticTexts.selectDropdown
        setErrorMessage(error)
      }
    }
  }

  useEffect(() => {
    if (listData) {
      const validData = listData.indexOf(
        listData.find(
          states =>
            states?.stateName?.toLowerCase() === stateChange?.toLowerCase()
        )
      )
      let data =
        validData > -1
          ? listData.at(validData)?.cityName?.map(data => {
              return { name: data }
            }) ?? []
          : []
      const isCity = data.some(ele => ele.name === cityChange)
      if (cityChange && !isCity) data = [...data, { name: cityChange }]
      setCityList(data)
    }
  }, [stateChange])
  const backToTop = () => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setBackToTopButton(true)
      } else {
        setBackToTopButton(false)
      }
    })
  }
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
    backToTopAnalytics()
  }
  const dataList =
    listData?.map(states => {
      return { name: states?.stateName }
    }) ?? []

  const handleStateChange = async value => {
    setStateChange(value)
    setServerList([])
    if (stateChange !== value) {
      setCityChange("")
    }
    stateSelectionAnalytics(value)
    const error = staticTexts.selectDropdown
    setErrorMessage(error)
  }

  const handleCityChange = async value => {
    setCityChange(value)
    if (value !== cityChange) setServerList([])
    if (value === "") {
      const error = staticTexts.selectDropdown
      setErrorMessage(error)
    }
    if (listData) {
      const validData = listData.indexOf(
        listData.find(
          states =>
            states?.stateName?.toLowerCase() === stateChange?.toLowerCase()
        )
      )
      let data =
        validData > -1
          ? listData.at(validData)?.cityName?.map(data => {
              return { name: data }
            }) ?? []
          : []
      setCityList(data)
      citySelectionAnalytics(value)
    }
  }

  return (
    <>
      <div className={styles.findStoreLanding}>
        <div className="find-a-store__margin">
          <div className="find-a-store__container">
            <div className="find-a-store__content find-a-store__flex-column">
              <div className="find-a-store__section">
                <p className="find-a-store__topic">{staticTexts?.buy}</p>
                <div className="find-a-store__selection">
                  <div className="find-a-store__select-dropdown">
                    <Autocomplete
                      id="find-a-store-select-state"
                      onChange={handleStateChange}
                      value={stateChange}
                      data={dataList}
                      label={staticTexts?.selectState}
                    />
                  </div>
                  <div className="find-a-store__select-dropdown">
                    <Autocomplete
                      id="find-a-store-select-city"
                      onChange={handleCityChange}
                      value={cityChange}
                      data={cityList}
                      label={staticTexts?.selectCity}
                    />
                  </div>
                </div>
              </div>
              {stateChange && cityChange ? (
                <StoreListing
                  storeCity={cityChange}
                  storeState={stateChange}
                  staticTexts={staticTexts}
                  storeList={storeList}
                  setError={() => {
                    const error = staticTexts.selectDropdown
                    setErrorMessage(error)
                  }}
                  error={errorMessage}
                />
              ) : (
                <NoStoreAvailable
                  staticTexts={staticTexts}
                  error={errorMessage}
                />
              )}
            </div>
          </div>
        </div>
        {backToTopButton && (
          <div className="find-a-store__back-to-top" onClick={scrollToTop}>
            {`${staticTexts?.backToTop}`} &#8593;
          </div>
        )}
      </div>
    </>
  )
}

export default FindAStore
