import { PLP_END_POINT_CLIENT, apiBaseUrl } from "@/constants/index"
import { apim } from "@/constants/api"

export const getBlogs = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: content } = await apim.get(
        `${apiBaseUrl}${PLP_END_POINT_CLIENT}?q=*:*&rows=50&profilename=profile_kohler-india_PLP_Blogs&collections=kohler-india&fl=id,url_s,thumbNailImage_s,title_s,releaseDate_s,category_ss`
      )
      resolve(content)
    } catch (error) {
      reject(error)
    }
  })
}
