import Arrow from "@/components/Default/CarouselSlick/v1/Arrow"

export const doubleFeatureContentCardSettings = {
  dots: false,
  infinite: true,
  fade: true,
  autoplay: false,
  cssEase: "ease-in-out",
  arrows: false,
  pauseOnFocus: false,
  pauseOnHover: false,
}

export const singleSliderSettings = (
  cb,
  topPosition,
  autoplay,
  autoplaySpeed,
  addAnalyticsForArrows
) => ({
  autoplay,
  autoplaySpeed,
  dots: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  swipe: true,
  arrows: true,
  infinite: true,
  centerMode: false,
  beforeChange: (_, current) => (cb ? cb(current + 1, current) : undefined),
  nextArrow: (
    <Arrow
      label="Next"
      topPosition={topPosition}
      addAnalyticsForArrows={addAnalyticsForArrows}
    />
  ),
  prevArrow: (
    <Arrow
      label="Previous"
      topPosition={topPosition}
      addAnalyticsForArrows={addAnalyticsForArrows}
    />
  ),
})
