import { useEffect, useState, useRef } from "react"
import styles from "@/components/ProductEnquiry/ProductEnquiryView/index.module.scss"
import useProductEnquiry from "@/i18n/useProductEnquiryi18n"
import ProductEnquiry from "@/components/ProductEnquiry/Form/ProductEnquiry"
import Confirmation from "@/components/ProductEnquiry/Confirmation"
import Enquiry from "@/components/ProductEnquiry/Form/Enquiry"
import { useDispatch, useSelector } from "react-redux"

const ProductEnquiryView = props => {
  const { data: authorData = {} } = props
  const confirmationRef = useRef(null)
  const staticText = useProductEnquiry(authorData)
  const [confirmationSuccess, setConfirmationSuccess] = useState(null)
  
  useEffect(() => {
    const scrollToTop = () => {
      window.scroll({
        top: 0,
        behavior: "smooth",
      })
    }
    if (confirmationSuccess) {
      if (authorData['isEnquiry']) {
        confirmationRef.current.scrollIntoView({ behaviour: "smooth", block: "center" })
      } else {
        scrollToTop()
      }
    }
  }, [confirmationSuccess])

  return (
    <>
      {!confirmationSuccess && (
        <div className={styles.productEnquiryLanding}>
          <div className={authorData['isEnquiry'] ? "product-enquiry enquiry" : "product-enquiry"}>
            <div className="product-enquiry__title">
              <h2 className="product-enquiry__heading">
                {authorData['isEnquiry'] ? staticText.enquiryTitle : staticText.mainTitle}
              </h2>
              <span className="product-enquiry__required-fields">
                {staticText.requiredInfoText}
              </span>
            </div>
            { !authorData['isEnquiry'] ? (
              <ProductEnquiry
              staticText={staticText}
              setConfirmationSuccess={setConfirmationSuccess}
            />)
            :
            (<Enquiry staticText={staticText} setConfirmationSuccess={setConfirmationSuccess} />
            )}
          </div>
        </div>
      )}
      {confirmationSuccess && (
        <div ref={confirmationRef} className={styles.productEnquiryLanding}>
          <div className="product-enquiry">
            <Confirmation staticText={staticText} />
          </div>
        </div>
      )}
    </>
  )
}

export default ProductEnquiryView
