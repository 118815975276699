import _isEmpty from "lodash/isEmpty"

import Button from "@/components/core/Button/Button"
import Modal from "@/components/core/Modal/Modal"
import { sanitizeInnerHtml } from "@/utils/helper"

const SubscribeInfoModal = props => {
  const {
    show,
    handleModalClose,
    homePagePath,
    className,
    staticTexts,
    modalContent: modalContent,
  } = props

  const { getReady, signupSuccess, getStarted } = staticTexts

  return (
    <Modal
      showModal={show}
      onModalClose={handleModalClose}
      modalTitle={getReady ?? ""}
      describedBy="newsletter-description"
      labelledBy={"newsletter-title"}
      className={`${className ? className : ""} newsletter`}
    >
      <div className="newsletter">
        <div className="newsletter__content">
          <div className="newsletter__thanks test-class" id="newsletter-title">
            {!_isEmpty(modalContent) ? (
              sanitizeInnerHtml(modalContent?.modalContent?.title)
            ) : (
              <h2>{sanitizeInnerHtml(getReady)}</h2>
            )}
          </div>
          <div className="newsletter__success" id="newsletter-description">
            {!_isEmpty(modalContent) ? (
              sanitizeInnerHtml(
                modalContent?.modalContent?.description
                  ?.split(process.env.NEXT_PUBLIC_INTERNALLINKPATH)
                  .join("")
                  .replace(".html", "")
              )
            ) : (
              <p>{sanitizeInnerHtml(signupSuccess)}</p>
            )}
          </div>
          {/* {enableCta && ( */}
          {false && (
            <Button
              type="black"
              role="button"
              label={getStarted}
              to={homePagePath}
            />
          )}
        </div>
      </div>
    </Modal>
  )
}

export default SubscribeInfoModal
