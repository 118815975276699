import React, { useEffect, useRef, useState } from "react"
import cx from "classnames"
import styles from "@/components/core/TextArea/index.module.scss"

const Input = props => {
  const { showError, errorMessage, id } = props
  const [focus, setFocus] = useState(false)
  const inputRef = useRef(null)
  const errorRef = useRef(null)
  useEffect(() => {
    if (props?.focus) {
      inputRef.current.focus()
    }
  }, [props?.focus])
  useEffect(() => {
    if (showError && errorMessage) {
      errorRef.current.focus()
    }
  }, [showError, errorMessage])
  return (
    <div className={styles.wrapper}>
      <div className="textarea__wrapper">
        <textarea
          value={props.value}
          placeholder={props.placeholder}
          onChange={props.onChange}
          onFocus={e => {
            setFocus(true)
            props.onFocus && props.onFocus(e)
          }}
          onBlur={e => {
            setFocus(false)
            props.onBlur && props.onBlur(e)
          }}
          maxLength={props.maxLength}
          className={`${props.className} ${
            props.showError ? "--with-error" : ""
          }`}
        />
        <label>{props.label}</label>
      </div>
      {showError && errorMessage && (
        <span
          ref={errorRef}
          aria-live="polite"
          role="alert"
          id={`${id}-error`}
          className="textarea__wrapper__error-message"
        >
          {errorMessage}
        </span>
      )}
    </div>
  )
}

export default Input
