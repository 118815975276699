import { playVideoDatalayer } from "@/components/Default/Video/videoAnalytics"
import { VIDEO_EVENTS } from "@/constants"

export const onVolumeChange = ref => {
  if (ref) {
    const { muted, volume } = ref
    if (volume == 0 && muted) {
      ref.setAttribute("volume", 1)
      ref.setAttribute("muted", false)
    }
  }
}

// will not work as the element is stacked behind. existing code also have same behavior
export const onClickHeroVideo = (event, ref) => {
  event.preventDefault()
  ref?.paused ? ref?.play() : ref?.pause()
}

export const onVideoInteraction = (
  action,
  cbVideoPlayed,
  cbVideoPlaying,
  ref,
  video,
  setFavVideoIdCb
) => {
  cbVideoPlayed()
  if (action === VIDEO_EVENTS.PLAY) {
    cbVideoPlaying(true)
    playVideoDatalayer(ref, video, setFavVideoIdCb)
  }
  if (action === VIDEO_EVENTS.PAUSE) {
    cbVideoPlaying(false)
  }
  if (action === VIDEO_EVENTS.ENDED) {
    playVideoDatalayer(ref, video, setFavVideoIdCb)
    ref.play()
  }
}

export const onPlayVideoInModal = (
  event,
  pageType,
  videoContainerRef,
  videoRef
) => {
  if (pageType == "modalWindow") {
    event.preventDefault()
    if (
      videoContainerRef &&
      !videoContainerRef?.classList?.contains("open-video-module")
    ) {
      videoContainerRef.classList.add("open-video-module")
      document.body.classList.add("modal-open")
      videoRef?.play()
    }
  }
}

export const onPlayVideo = (videoRef, cb) => {
  if (videoRef?.paused) {
    videoRef?.play()
  } else {
    videoRef?.pause()
  }
  const cbRes = videoRef?.paused ? true : false
  cb && cb(cbRes)
}

export const onVideoPopupClose = (videoContainerRef, videoRef, video) => {
  videoContainerRef.classList.remove("open-video-module")
  document.body.classList.remove("modal-open")
  videoRef?.pause()
  playVideoDatalayer(videoRef, video, null, true)
}

export const onShareVideo = (e, shareVideoCallback) => {
  shareVideoCallback && shareVideoCallback()
  if (window.shareSrcElement === undefined || window.shareSrcElement === null) {
    window.shareSrcElement = e.target.children[0]
  }
}

export const onFavVideo = (
  event,
  asset = "",
  url = "",
  title = "",
  caption = "",
  videoname = "",
  favVideoId = "",
  favouriteCallback
) => {
  let _$$closest
  const id = event.target.getAttribute("data-id")
  const sharedZoneName =
    (_$$closest = $(void 0).closest(".cmp-container")) === null ||
    _$$closest === void 0
      ? void 0
      : _$$closest.attr("id")

  if (id) {
    window.removeFavorites(
      [id],
      "",
      "",
      null,
      caption,
      sharedZoneName,
      videoname,
      favVideoId
    )
    favouriteCallback && favouriteCallback(false)
  } else {
    const assetUrl = asset

    let _assetUrl
    const url =
      (_assetUrl = assetUrl) !== null &&
      _assetUrl !== void 0 &&
      _assetUrl.startsWith("/")
        ? window.location.origin + assetUrl
        : assetUrl
    window.addMediaToFavorites &&
      window.addMediaToFavorites(
        "video",
        title,
        url,
        "",
        "",
        caption,
        sharedZoneName,
        videoname,
        favVideoId
      )

    favouriteCallback && favouriteCallback(true)
  }
}
