import React, { Suspense } from "react"
import cx from "classnames"
import dynamic from "next/dynamic"
import { capitalizeFirstName } from "@/utils/helper"
import {
  selectAuthState,
  setAuthModalVisibility,
} from "@/store/features/authSlice"
import { useDispatch, useSelector } from "react-redux"
import useWindowDimensions from "@/hooks/useWindowDimensions"
const MegaMenu = dynamic(
  () => import("@/components/Default/AuthUtilityNav/v1/MegaMenu"),
  {
    suspense: true,
  }
)

const AuthUtilityNavView = props => {
  const avatarIcon = "/icons/avatar.svg"
  const rightArrow = "/icons/right-white.svg"

  const {
    active,
    i18n,
    userMenuFragment,
    guestMenuFragment,
    isLoggedIn,
    logout,
    toggleAuthNav,
    setAuthNav,
    onAuthMenuClick,
    nomenu = false,
    setCloseAuthMenu = () => {},
    isFromUtiltyWidget = false,
  } = props
  const { user } = useSelector(selectAuthState)

  const { winWidth } = useWindowDimensions()

  const styleClass = cx({ "auth-nav": true, "auth-nav--active": active })

  const dispatch = useDispatch()

  const onClickSignIn = () => {
    dispatch(setAuthModalVisibility({ show: true }))
  }

  const onClickSignUp = () => {
    dispatch(setAuthModalVisibility({ show: true, active: 1 }))
  }

  const showMenuNav = () => {
    toggleAuthNav()
  }

  const signInEventInfo = JSON.stringify({
    eventName: "sign in start",
    eventAction: "sign in start",
    internalLinkName: "sign in start",
    internalLinkType: "global header:navigation",
    internalLinkZoneName: "global header",
    clickInternalLinks: "true",
    eventType: "global header",
    internalLinkURL: "n/a",
    internalLinkPosition: "global header sign in start",
    eventMsg: "n/a",
    eventStatus: "n/a",
  })

  return (
    <div className={styleClass} aria-expanded={active}>
      {winWidth <= 1200 ? (
        isLoggedIn ? (
          <React.Fragment>
            <div
              tabIndex="0"
              aria-label={`${i18n.hi} ${capitalizeFirstName(user?.firstName)}`}
              role="button"
              className="auth-nav__sign-in-mobile"
              onClick={() => {
                toggleAuthNav()
                onAuthMenuClick(!active)
                setAuthNav(!active)
              }}
            >
              {/* <img
                name="avatarIcon"
                src={avatarIcon}
                alt={i18n.signIn}
                className="gbh-data-layer auth-nav__sign-in-avatar"
                data-gbh-data-layer={signInEventInfo}
              /> */}
              <span name="loggedInUser" className="auth-nav__user">
                {i18n.hi}, {capitalizeFirstName(user?.firstName)}
              </span>
              <img
                src={rightArrow}
                alt={i18n.rightArrowAlt}
                className="auth-nav__sign-in-right-arrow"
              />
            </div>
          </React.Fragment>
        ) : (
          <Suspense fallback="Loading...">
            <MegaMenu
              userMenuFragment={userMenuFragment}
              guestMenuFragment={guestMenuFragment}
              isLoggedIn={isLoggedIn}
              i18n={i18n}
              user={user}
              logout={logout}
              toggleAuthNav={toggleAuthNav}
              active={active}
              handleSignin={onClickSignIn}
              handleCreateAccount={onClickSignUp}
            />
          </Suspense>
        )
      ) : isLoggedIn ? (
        <span
          tabIndex="0"
          role="button"
          aria-label={
            active
              ? `${i18n.user} ${i18n.expanded}`
              : `${i18n.user} ${i18n.collapsed}`
          }
          name="loggedInUser"
          className="auth-nav__user"
          onClick={showMenuNav}
        >
          {i18n.hi}, {capitalizeFirstName(user?.firstName)}
        </span>
      ) : (
        <img
          tabIndex="0"
          role="button"
          name="avatarIcon"
          aria-label={
            active
              ? `${i18n.signIn} ${i18n.expanded}`
              : `${i18n.signIn} ${i18n.collapsed}`
          }
          src={avatarIcon}
          alt={i18n.signIn}
          onClick={showMenuNav}
          className="gbh-data-layer"
          data-gbh-data-layer={signInEventInfo}
        />
      )}
      <div
        className={`auth-nav__menu ${
          isFromUtiltyWidget ? "auth-nav__menu-login" : ""
        }   ${isLoggedIn ? "auth-nav__menu-expand" : ""} ${
          active ? "auth-nav__menu-open" : ""
        }`}
        aria-hidden={!active}
      >
        {active ? (
          <Suspense fallback="Loading...">
            <MegaMenu
              userMenuFragment={userMenuFragment}
              guestMenuFragment={guestMenuFragment}
              isLoggedIn={isLoggedIn}
              i18n={i18n}
              user={user}
              logout={logout}
              toggleAuthNav={toggleAuthNav}
              handleSignin={onClickSignIn}
              handleCreateAccount={onClickSignUp}
              onAuthMenuClick={onAuthMenuClick}
              nomenu={nomenu}
              setCloseAuthMenu={setCloseAuthMenu}
            />
          </Suspense>
        ) : null}
      </div>
    </div>
  )
}

AuthUtilityNavView.displayName = "AuthUtilityNavView"

export default AuthUtilityNavView
