import { closest } from "@/utils/index"
import isEmpty from "lodash/isEmpty"

let analyticsTimer

export const pushClickEvent = eventInfo => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}

  if (isEmpty(page)) {
    analyticsTimer = setTimeout(() => pushClickEvent(eventInfo), 500)
    return
  }

  if (analyticsTimer) {
    clearTimeout(analyticsTimer)
  }

  Object.keys(eventInfo).forEach(key => {
    if (eventInfo[key].includes(":rm")) {
      let _eventInfo$key
      eventInfo[key] =
        (_eventInfo$key = eventInfo[key]) === null || _eventInfo$key === void 0
          ? void 0
          : _eventInfo$key.replace(/:rm/g, "")
    }
  })
  if (eventInfo) {
    window.adobeDataLayer.push({
      event: "cmp:click",
      eventInfo: eventInfo,
      page: page,
    })
  }
}

export const getComponentZone = element => {
  const container = closest(element, el => el.className === "cmp-container")
  const zoneName = container.getAttribute("id")
  if (zoneName) {
    return zoneName.toLowerCase().replaceAll("-", " ")
  } else {
    return ""
  }
}

export const playVideoDatalayer = (
  element,
  video,
  setFavVideoIdCb,
  videoModalClose
) => {
  const container = closest(element, el =>
    el.className.includes("cmp-teaser__image")
  )
  const nearestDatalayerAttribute = container?.getAttribute(
    "data-gbh-data-layer"
  )
  const videoIdAttribute = video
  const jsonObject = JSON.parse(
    nearestDatalayerAttribute.replaceAll(/(\r\n|\n|\r)/gm, "")
  )
  jsonObject["internalLinkZoneName"] =
    window.eventPageType + ":" + getComponentZone(element)
  jsonObject["eventName"] = jsonObject["eventName"].replaceAll(
    "zoneForVideo",
    getComponentZone(element)
  )
  // splitting video id from URL
  const splitVideoId = decodeURI(videoIdAttribute).split("PAWEB/")[1]
  if (videoIdAttribute && undefined !== splitVideoId && null !== splitVideoId) {
    jsonObject["videoId"] = splitVideoId.toLowerCase()
    const favVideoId = splitVideoId.toLowerCase()
    setFavVideoIdCb && setFavVideoIdCb(favVideoId)
  }
  // condition to check when the video ends
  if (videoModalClose) {
    const { eventName = "" } = jsonObject
    const closeEvent = `${eventName}:close`
    const videoJsonObject = {
      ...jsonObject,
      eventAction: closeEvent,
      eventName: closeEvent,
      internalLinkName: "close",
      internalLinkType: window.eventPageType + ":" + "close",
    }
    pushClickEvent(videoJsonObject)
  } else {
    if (element.ended) {
      jsonObject["internalLinkName"] = "video complete"
      jsonObject["eventName"] = jsonObject["eventAction"] =
        jsonObject["eventName"] + ":" + "video complete"
    } else {
      jsonObject["eventName"] = jsonObject["eventAction"] =
        jsonObject["eventName"] + ":" + "video play"
    }

    pushClickEvent(jsonObject)
  }
}
