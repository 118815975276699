import React, { useEffect, useRef, useState } from "react"
import Accordion from "@/components/core/Accordion/Accordion"
import useWindowResize from "@/hooks/useWindowResize"
import backToTopIcon from "@/public/icons/back-top-arrow.svg"
import { staticClasses } from "@/constants"
import {
  getActiveHash,
  handleHashchangeEvent,
  handleResizeEvent,
  onDocumentReady,
  setTabAnimation,
} from "@/components/Default/Tab/v1/TabHelper"
import { cleanText } from "@/utils/helper"
import {
  pushAnalyticsObjToDataLayer,
  removeAndCleanLinkConcat,
} from "@/utils/helper"

const CollapsibleFooter = props => {
  const menuVisible = useRef(0)
  const backToTopRef = useRef(null)
  const {
    data = {
      accordionProperties: {
        [":items"]: {
          tabs: {
            [":items"]: {},
          },
        },
      },
    },
  } = props
  const [navOpen, setNavOpen] = useState(
    data?.accordionProperties?.[":items"]?.tabs?.activeItem
  )
  const [menuOpen, setMenuOpen] = useState(false)
  const [width] = useWindowResize()
  const [isMobile, setIsMobile] = useState(typeof window === 'undefined' ? false : width <= 1024)
  useEffect(() => {
    setIsMobile(width <= 1024)
  }, [width])

  useEffect(() => {
    const selectionBar = document?.querySelectorAll(".selectionBarcss")
    if (!selectionBar?.length) {
      onDocumentReady()
      getActiveHash()
      window?.addEventListener("resize", handleResizeEvent)
      window?.addEventListener("hashchange", handleHashchangeEvent)
      // Create selectionBarDiv element
      const selectionBarDiv = document?.createElement("div")
      selectionBarDiv.className = "selectionBarcss"
      // Loop through each .tabs element
      const tabContainer = document?.querySelectorAll(".tabs")
      tabContainer?.forEach(tabs => {
        if (!tabs?.classList?.contains("gbh-global-tab")) {
          const tab = tabs?.querySelector(".cmp-tabs__tablist")
          tab?.insertAdjacentElement("afterbegin", selectionBarDiv)

          const additionalCss =
            tabs?.classList?.contains("vertical-tabs") &&
            window?.matchMedia("(min-width: 1201px)")?.matches
              ? "selectionBar-vertical"
              : "selectionBar-horizontal"
          tab?.querySelector(".selectionBarcss")?.classList?.add(additionalCss)

          const activeTab = tab?.querySelector(
            ".cmp-tabs__tab.cmp-tabs__tab--active"
          )
          if (activeTab) setTabAnimation(activeTab)
          const tabChildContainer = tab?.querySelectorAll(".cmp-tabs__tab")
          tabChildContainer?.forEach(tabElement => {
            tabElement?.addEventListener("click", (event = {}) => {
              const { currentTarget = {} } = event
              const clickedTab = currentTarget
              // Move the tabbed navigation to the center of viewport when clicking
              clickedTab?.scrollIntoView({ block: "nearest", inline: "center" })
              if (clickedTab) setTabAnimation(clickedTab)
            })
          })
        }
      })
    }

    return () => {
      window?.removeEventListener("resize", handleResizeEvent)
      window?.removeEventListener("hashchange", handleHashchangeEvent)
    }
  }, [])

  const onClickNav = data => {
    if (navOpen !== data["cq:panelTitle"]) {
      setNavOpen(data["cq:panelTitle"])
      menuVisible.current = 1
      tabNavAnalytics(data["cq:panelTitle"])
    }
  }

  const itemsOrder =
    data?.accordionProperties?.[":items"]?.tabs?.[":itemsOrder"]
  const items = data?.accordionProperties?.[":items"]?.tabs?.[":items"]

  const eventPageType = "global footer"

  const expandAnalytics = () => {
    pushAnalyticsObjToDataLayer({
      clickInternalLinks: "true",
      eventAction: `${eventPageType}:tabs:accordion ${
        menuOpen ? "collapse" : "expand"
      }`,
      eventName: `${eventPageType}:tabs:accordion ${
        menuOpen ? "collapse" : "expand"
      }`,
      eventType: "navigation",
      internalLinkName: `accordion ${menuOpen ? "collapse" : "expand"}`,
      internalLinkPosition: eventPageType,
      internalLinkType: `${eventPageType}:tab navigation`,
      internalLinkZoneName: `${eventPageType}:tabs`,
      internalLinkURL: "n/a",
    })
  }

  const tabNavAnalytics = tabName => {
    const eventInfo = {
      clickInternalLinks: "true",
      eventAction: `${eventPageType}:tabs:tab navigation`,
      eventName: `${eventPageType}:tabs:tab navigation`,
      eventType: "navigation",
      internalLinkName: tabName?.toLowerCase() ?? "",
      internalLinkPosition: eventPageType,
      internalLinkType: `${eventPageType}:tab navigation`,
      internalLinkZoneName: `${eventPageType}:tabs`,
      internalLinkURL: "n/a",
    }
    pushAnalyticsObjToDataLayer(eventInfo)
  }

  let scrollTimer
  let lastScrollTop = 0

  const handleScroll = () => {
    const backToTopBtn = backToTopRef?.current
    const currentScroll =
      window.pageYOffset || document.documentElement.scrollTop
    if (currentScroll < lastScrollTop && currentScroll > 50) {
      backToTopBtn.style.display = "block"
      clearTimeout(scrollTimer)
      scrollTimer = setTimeout(() => {
        backToTopBtn.style.display = "none"
      }, 3000)
    } else {
      backToTopBtn.style.display = "none"
    }
    lastScrollTop = currentScroll <= 0 ? 0 : currentScroll
  }

  useEffect(() => {
    const backToTopBtn = backToTopRef?.current
    backToTopBtn.style.display = "none"
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const handleBackToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    document.getElementById(`kf-product-list-tile-details-0`)?.focus()
  }

  const tabLinkAnalytics = (option, category) => {
    const { subDisplayName = "", subPageLink = "/" } = option
    const categoryName = cleanText(category)
      .replace(/&amp;/g, "&")
      .replace(/\r/, "")
      .replace(/\n/g, "")
      .toLowerCase()
    const tabName = navOpen?.toLowerCase() ?? ""
    const linkText = subDisplayName.toLowerCase()
    const linkUrl =
      window.location.origin + removeAndCleanLinkConcat(subPageLink)
    const eventInfo = {
      clickInternalLinks: "true",
      eventAction: `${eventPageType}:tabs:${tabName}:${linkText}`,
      eventName: `${eventPageType}:tabs:${tabName}:${linkText}`,
      eventType: "navigation",
      internalLinkName: linkText,
      internalLinkPosition: eventPageType,
      internalLinkType: `${eventPageType}:${categoryName}`,
      internalLinkZoneName: `${eventPageType}:tabs:${tabName}`,
      internalLinkURL: linkUrl,
    }
    pushAnalyticsObjToDataLayer(eventInfo)
  }

  return (
    <>
      <Accordion
        isOpen={false}
        isArrow={true}
        contentElementId="collapsible-footer-content"
        triggerElementId="collapsible-footer"
        handleOpen={() => setMenuOpen(true)}
        handleClose={() => setMenuOpen(false)}
        expandAnalyticsEvent={expandAnalytics}
      >
        <div className="cmp-accordion__item">
          <div className="cmp-accordion__panel">
            <div className="tabs panelcontainer bg-slate">
              <div className="cmp-tabs">
                <ol
                  role="tablist"
                  className="cmp-tabs__tablist"
                  aria-multiselectable="false"
                >
                  {itemsOrder?.map((key, index) => {
                    const item = items[key]
                    return (
                      <>
                        <li
                          role="tab"
                          className={`cmp-tabs__tab ${
                            navOpen == item["cq:panelTitle"]
                              ? "cmp-tabs__tab--active"
                              : ""
                          }`}
                          onClick={() => onClickNav(item)}
                          tabIndex="0"
                        >
                          {item["cq:panelTitle"]}
                        </li>
                        {isMobile && navOpen === item["cq:panelTitle"] && (
                          <div
                            key={`navTabs-${index}`}
                            role="tabpanel"
                            tabIndex="-1"
                            className={`cmp-tabs__tabpanel ${
                              navOpen === item["cq:panelTitle"]
                                ? "cmp-tabs__tabpanel--active"
                                : ""
                            }`}
                          >
                            <div className={`${staticClasses.CONTAINER}`}>
                              <div className="cmp-container">
                                {Object.values(item[":itemsOrder"])?.map(
                                  (ele, i) => {
                                    return (
                                      <div className="list" key={i}>
                                        <div className="list-inner">
                                          <h3
                                            dangerouslySetInnerHTML={{
                                              __html: DOMPurify.sanitize(
                                                item?.[":items"]?.[ele]
                                                  ?.siteMapHeading
                                              ),
                                            }}
                                          />
                                          {item?.[":items"]?.[ele]?.[
                                            "optionsSubHeader"
                                          ]?.map((option, i) => {
                                            return (
                                              <React.Fragment
                                                key={`gbh-footer-main-nav-link-${i}`}
                                              >
                                                <li>
                                                  <a
                                                    href={
                                                      option?.subPageLink
                                                        ?.split(
                                                          process.env
                                                            .NEXT_PUBLIC_INTERNALLINKPATH
                                                        )
                                                        .join("")
                                                      // .replace(".html", "")
                                                    }
                                                    aria-label={`${
                                                      option?.subDisplayName
                                                    }${
                                                      option?.isNewTab
                                                        ? " external link opens in a new tab"
                                                        : ""
                                                    }`}
                                                    target={`${
                                                      option?.isNewTab
                                                        ? "_blank"
                                                        : "_self"
                                                    }`}
                                                    rel="noreferrer"
                                                    onClick={() =>
                                                      tabLinkAnalytics(option)
                                                    }
                                                  >
                                                    {option?.subDisplayName}
                                                  </a>
                                                </li>
                                              </React.Fragment>
                                            )
                                          })}
                                          {item?.[":items"]?.[ele]?.[
                                            "options"
                                          ]?.map((option, i) => {
                                            return (
                                              <React.Fragment
                                                key={`gbh-footer-main-nav-link-${i}`}
                                              >
                                                <li>
                                                  <h3
                                                    dangerouslySetInnerHTML={{
                                                      __html:
                                                        DOMPurify.sanitize(
                                                          option?.subHeader
                                                        ),
                                                    }}
                                                  />
                                                </li>
                                              </React.Fragment>
                                            )
                                          })}
                                        </div>
                                      </div>
                                    )
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )
                  })}
                </ol>
                {itemsOrder?.map((key, index) => {
                  const item = items[key]
                  if (!isMobile && navOpen === item["cq:panelTitle"])
                    return (
                      <div
                        key={`navTabs-${index}`}
                        role="tabpanel"
                        tabIndex="-1"
                        className={`cmp-tabs__tabpanel ${
                          navOpen === item["cq:panelTitle"]
                            ? "cmp-tabs__tabpanel--active"
                            : ""
                        }`}
                      >
                        <div className={`${staticClasses.CONTAINER}`}>
                          <div className="cmp-container">
                            {Object.values(item[":itemsOrder"])?.map(
                              (ele, i) => {
                                return (
                                  <div className="list" key={i}>
                                    <div className="list-inner">
                                      <h3
                                        dangerouslySetInnerHTML={{
                                          __html: DOMPurify.sanitize(
                                            item?.[":items"]?.[ele]
                                              ?.siteMapHeading
                                          ),
                                        }}
                                      />
                                      {item?.[":items"]?.[ele]?.[
                                        "optionsSubHeader"
                                      ]?.map((option, i) => {
                                        return (
                                          <React.Fragment
                                            key={`gbh-footer-main-nav-link-${i}`}
                                          >
                                            <li>
                                              <a
                                                href={removeAndCleanLinkConcat(
                                                  option?.subPageLink
                                                )}
                                                aria-label={`${
                                                  option?.subDisplayName
                                                }${
                                                  option?.isNewTab
                                                    ? " external link opens in a new tab"
                                                    : ""
                                                }`}
                                                target={`${
                                                  option?.isNewTab
                                                    ? "_blank"
                                                    : "_self"
                                                }`}
                                                rel="noreferrer"
                                                onClick={() =>
                                                  tabLinkAnalytics(
                                                    option,
                                                    item?.[":items"]?.[ele]
                                                      ?.siteMapHeading
                                                  )
                                                }
                                              >
                                                {option?.subDisplayName}
                                              </a>
                                            </li>
                                          </React.Fragment>
                                        )
                                      })}
                                      {item?.[":items"]?.[ele]?.[
                                        "options"
                                      ]?.map((option, i) => {
                                        return (
                                          <React.Fragment
                                            key={`gbh-footer-main-nav-link-${i}`}
                                          >
                                            <li>
                                              <h3
                                                dangerouslySetInnerHTML={{
                                                  __html: DOMPurify.sanitize(
                                                    option?.subHeader
                                                  ),
                                                }}
                                              />
                                            </li>
                                          </React.Fragment>
                                        )
                                      })}
                                    </div>
                                  </div>
                                )
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    )
                })}
              </div>
            </div>
          </div>
        </div>
      </Accordion>
      {/* back to top sticky */}
      <div className="stickychattop" ref={backToTopRef}>
        <button
          role="link"
          className="gbh-data-layer stickytop"
          onClick={handleBackToTop}
        >
          <img
            aria-hidden="true"
            src={backToTopIcon?.src}
            alt="Back to Top"
            className="backtopimg"
          />
          <span className="backtoptext">Back to Top</span>
        </button>
      </div>
    </>
  )
}

export default CollapsibleFooter
