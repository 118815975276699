import React, { useEffect, useState, useRef } from "react"
import { OverlayScrollbarsComponent } from "overlayscrollbars-react"
import DOMPurify from "isomorphic-dompurify"
import _isEmpty from "lodash/isEmpty"
import useIsSsr from "@/hooks/useIsSsr"
import { ConditionalWrapper } from "@/utils/index"
import { COMPONENT_TYPES } from "@/constants/index"
import AemGrid from "@/components/AemGrid"
import CarouselFiller from "@/components/Default/Carousel/v1/CarouselFiller"

const Carousel = props => {
  const {
    data: {
      carouselComponentProperties: {
        appliedCssClassNames = "",
        ":itemsOrder": itemsOrder = [],
        ":items": items = [],
        autoplay = false,
      } = {},
      bodytitle = "",
      texttitle = "",
      description = "",
      showSlideIndicators = true,
      slidertype = "",
    } = {},
    fullData = {},
  } = props

  const isSsr = useIsSsr()
  const [isOverlayScrollbar, setIsOverlayScrollbar] = useState(false)
  const [componentGroup, setComponentGroup] = useState([])
  const [customClassNames, setCustomClassNames] = useState("")
  const [cmpCarouselCustomClass, setCmpCarouselCustomClass] = useState("")
  const [cmpCarouselCurrentImage, setCmpCarouselCurrentImage] = useState(0)

  const scrollOptions = {
    className: "os-theme-dark cmp-carousel__whole",
    style: "height:auto",
    scrollbars: {
      visibility: "auto",
      autoHide: "never",
    },
  }
  const showCarouselText = bodytitle || texttitle || description
  const scrollerRef = useRef()
  useEffect(() => {
    if (scrollerRef.current) {
      setTimeout(() => {
        document
          .querySelectorAll(".carousel-bottom-scroll")
          .forEach((scrollDiv, index) => {
            let heightToSet = 0
            scrollDiv
              ?.querySelectorAll(".cmp-carousel__content")
              .forEach((scrollDivContent, index) => {
                if (
                  scrollDivContent &&
                  heightToSet < scrollDivContent?.offsetHeight
                ) {
                  heightToSet = scrollDivContent?.offsetHeight
                }
              })
            scrollDiv
              ?.querySelectorAll(".os-content-glue")
              .forEach((scrollDivglu, index) => {
                if (scrollDivglu && heightToSet > 0) {
                  scrollDivglu.style.height = heightToSet + "px"
                }
              })
          })
      }, 3000)
    }
  }, [scrollerRef.current])
  useEffect(() => {
    if (
      appliedCssClassNames?.includes("carousel-bottom-scroll") ||
      appliedCssClassNames?.includes("carousel-btm-scroll-title-small")
    ) {
      setIsOverlayScrollbar(true)
    }
  }, [])

  useEffect(() => {
    if (!_isEmpty(fullData)) {
      Object.keys(fullData).forEach(key => {
        setComponentGroup(cmpGroup => [...cmpGroup, fullData[key][":type"]])
      })
    }
  }, [fullData])

  useEffect(() => {
    itemsOrder.forEach((key, i) => {
      const item = items[key]
      if (
        item?.appliedCssClassNamesCustom?.includes(
          "hero-banner-des-medium-center"
        ) &&
        i === 0 &&
        !customClassNames?.includes("center-double-btn")
      ) {
        setCustomClassNames(className => (className += " center-double-btn"))
      }
    })
  }, [itemsOrder])

  useEffect(() => {
    if (componentGroup.length > 0) {
      if (
        componentGroup.find(a => a?.includes(COMPONENT_TYPES.BREADCRUMB)) &&
        componentGroup.find(a => a?.includes(COMPONENT_TYPES.CAROUSEL)) &&
        !customClassNames?.includes(
          "breadcrums-withcarosel paddingbasedbreadcrum"
        )
      ) {
        setCustomClassNames(
          className =>
            (className += " breadcrums-withcarosel paddingbasedbreadcrum")
        )
      }
      if (
        componentGroup.find(a => a?.includes(COMPONENT_TYPES.BREADCRUMB)) &&
        componentGroup.find(a => a?.includes(COMPONENT_TYPES.CAROUSEL)) &&
        !customClassNames?.includes(
          "aem-GridColumn aem-GridColumn--default--12"
        )
      ) {
        setCustomClassNames(
          className =>
            (className += " aem-GridColumn aem-GridColumn--default--12")
        )
      }
    }
  }, [componentGroup])

  useEffect(() => {
    if (
      appliedCssClassNames?.includes("carousel-btm-scroll-title-small") &&
      !cmpCarouselCustomClass?.includes("carousel-btm-scroll-title-small")
    ) {
      setCmpCarouselCustomClass(className => (className += " slide-scrollbar"))
    }
  }, [appliedCssClassNames])

  return (
    <div
      className={`carousel ${
        appliedCssClassNames ? appliedCssClassNames : ""
      }${customClassNames}`}
    >
      <div className={`cmp-carousel${cmpCarouselCustomClass}`}>
        {showCarouselText ? (
          <div className="cmp-carousel-text">
            {bodytitle ? (
              <div
                className="cmp-carousel__pretitle"
                role="heading"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(bodytitle),
                }}
              />
            ) : null}
            {texttitle ? (
              <div
                className="cmp-carousel__title"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(texttitle),
                }}
              />
            ) : null}
            {description ? (
              <div
                className="cmp-carousel__description"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(description),
                }}
              />
            ) : null}
          </div>
        ) : null}
        {!isSsr ? (
          <ConditionalWrapper
            condition={isOverlayScrollbar}
            wrapper={children => (
              <OverlayScrollbarsComponent
                options={scrollOptions}
                ref={scrollerRef}
                defer
              >
                {children}
              </OverlayScrollbarsComponent>
            )}
          >
            <div className="cmp-carousel__content">
              {itemsOrder &&
                itemsOrder.map((key, i) => {
                  const item = items[key]
                  return (
                    <div
                      key={`carousel-${i}`}
                      className={`cmp-carousel__item${
                        i === cmpCarouselCurrentImage ? " cmp-carousel__item--active" : ""
                      }`}
                      role="tabpanel"
                      aria-label={`Slide ${i + 1} of ${itemsOrder?.length}`}
                    >
                      <AemGrid data={item} />
                    </div>
                  )
                })}

              {itemsOrder?.length > 1 && (
                <div className="cmp-carousel__actions">
                  <button
                    className="cmp-carousel__action cmp-carousel__action--previous"
                    type="button"
                    aria-label="Previous"
                    data-cmp-hook-carousel="previous"
                  >
                    <span className="cmp-carousel__action-icon"></span>
                    <span className="cmp-carousel__action-text">
                      {"Previous"}
                    </span>
                  </button>
                  <button
                    className="cmp-carousel__action cmp-carousel__action--next"
                    type="button"
                    aria-label="Next"
                    data-cmp-hook-carousel="next"
                    onClick={() => cmpCarouselCurrentImage === itemsOrder.length - 1 ? setCmpCarouselCurrentImage(0) : setCmpCarouselCurrentImage(cmpCarouselCurrentImage + 1)}
                  >
                    <span className="cmp-carousel__action-icon"></span>
                    <span className="cmp-carousel__action-text">{"Next"}</span>
                  </button>
                  {autoplay && (
                    <button
                      className="cmp-carousel__action cmp-carousel__action--pause"
                      type="button"
                      aria-label="Pause"
                      data-cmp-hook-carousel="pause"
                    >
                      <span className="cmp-carousel__action-icon"></span>
                      <span className="cmp-carousel__action-text">
                        {"Pause"}
                      </span>
                    </button>
                  )}
                  {autoplay && (
                    <button
                      className="cmp-carousel__action cmp-carousel__action--play cmp-carousel__action--disabled"
                      type="button"
                      aria-label="Play"
                      data-cmp-hook-carousel="play"
                    >
                      <span className="cmp-carousel__action-icon"></span>
                      <span className="cmp-carousel__action-text">
                        {"Play"}
                      </span>
                    </button>
                  )}
                </div>
              )}
              {(slidertype === "slideindicator" || showSlideIndicators) && (
                <ol
                  className={`${
                    slidertype === "slideindicator" || showSlideIndicators
                      ? "cmp-carousel__indicators"
                      : "cmp-carousel__hyphen"
                  }`}
                  role="tablist"
                  aria-label="Choose a slide to display"
                  data-cmp-hook-carousel="indicators"
                >
                  {itemsOrder &&
                    itemsOrder?.length > 1 &&
                    itemsOrder.map((key, i, arr) => {
                      return (
                        <li
                          key={`carousel-${i}`}
                          className={`cmp-carousel__indicator${
                            i === 0 ? " cmp-carousel__indicator--active" : ""
                          }`}
                          role="tab"
                          aria-label={`Slide ${i + 1}`}
                          data-cmp-hook-carousel="indicator"
                        >
                          <span className="cmp-carousel__indicator--activeNum">
                            {i + 1}
                          </span>
                          {`/ ${arr?.length}`}
                        </li>
                      )
                    })}
                </ol>
              )}
            </div>
          </ConditionalWrapper>
        ) : (
          <CarouselFiller itemsOrder={itemsOrder} items={items} />
        )}
      </div>
    </div>
  )
}

export default Carousel
