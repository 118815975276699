import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import AemGrid from "@/components/AemGrid"
import Script from "next/script"
import {
  getPageLevelScriptsAndStyles,
  getScriptsAndStyles,
} from "@/utils/helper"
import {
  setGlobalStyles,
  setGlobalScripts,
  selectGenericState,
} from "@/store/features/genericSlice"
import { getConfig } from "@/constants/config"
import { selectAuthState } from "@/store/features/authSlice"
import { FRAGMENT_TYPES } from "@/constants"
const GlobalPageFooter = props => {
  const { footer, footerScripting } = props
  const [scripts, setScripts] = useState([])
  const [bvScript, setBVScript] = useState({})
  const [ymConfig, setYmConfig] = useState(null)
  const dispatch = useDispatch()
  const { isAuth, user } = useSelector(selectAuthState)
  const { pageIsInteractive } = useSelector(selectGenericState)
  const { src, type, integrity } = bvScript
  useEffect(() => {
    const { pageLevelDetails = [], bazaarVoice = null } =
      getPageLevelScriptsAndStyles(footerScripting)
    setScripts(pageLevelDetails["pageScripts"])
    if (bazaarVoice) {
      setBVScript(bazaarVoice)
    }
  }, [footerScripting])
  useEffect(() => {
    getScriptsAndStyles(footer)
  }, [footer])

  useEffect(() => {
    getConfig().then(async config => {
      const { apiEndpoints: { chatbotId = "", chatbotHost = "" } = {} } = config
      const botConfig = {
        bot: chatbotId,
        host: chatbotHost,
      }
      if (isAuth && user?.sub) {
        const {
          sub: email = "",
          firstName: name = "",
          login: phoneNumber = "",
        } = user
        const payload = { name, phoneNumber, email }
        botConfig.payload = payload
        window.YellowMessengerPlugin?.init({ payload })
      }
      setYmConfig(botConfig)
    })
  }, [isAuth, user])

  const chatScript = `(function () {
    var w = window,
      ic = w.YellowMessenger;
    if ("function" === typeof ic)
      ic("reattach_activator"), ic("update", ymConfig);
    else {
      var d = document,
        i = function () {
          i.c(arguments);
        };
      function l() {
        var e = d.createElement("script");
        (e.type = "text/javascript"),
          (e.async = !0),
          (e.src =
            "https://cdn.yellowmessenger.com/plugin/widget-v2/latest/dist/main.min.js");
        var t = d.getElementsByTagName("script")[0];
        t.parentNode.insertBefore(e, t);
      }
      (i.q = []),
        (i.c = function (e) {
          i.q.push(e);
        }),
        (w.YellowMessenger = i),
        w.attachEvent
          ? w.attachEvent("onload", l)
          : w.addEventListener("load", l, !1);
    }
  })();`

  const eventHandler = {
    store: {},
    callbacks: {
      setGlobalScripts: item => {
        dispatch(setGlobalScripts(item))
      },
      setGlobalStyles: item => {
        dispatch(setGlobalStyles(item))
      },
    },
  }
  return (
    <>
      {pageIsInteractive &&
        scripts.map((script, i) => {
          if (script.src)
            return (
              <Script
                key={`footerscript-${i}`}
                src={script.src}
                type={script.type}
                integrity={script.integrity}
                id={`footerscript-${i}`}
              />
            )
          if (!script.src && script.innerHTML)
            return (
              <Script
                key={`footerscriptHtml-${i}`}
                id={`footerscriptHtml-${i}`}
              >
                {`${script.innerHTML}`}
              </Script>
            )
        })}
      {src ? (
        <Script
          id="footerscript-bv"
          src={src}
          type={type}
          integrity={integrity}
        />
      ) : null}
      {ymConfig && (
        <Script id="footerscript-ym" type="text/javascript">
          {`window.ymConfig = ${JSON.stringify(ymConfig)};`}
          {`${chatScript}`}
        </Script>
      )}
      <AemGrid
        data={footer}
        count={0}
        eventHandler={eventHandler}
        fragment={FRAGMENT_TYPES.FOOTER}
      />
    </>
  )
}
export default GlobalPageFooter
