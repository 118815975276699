import React, { useState, useEffect, useRef } from "react"
import { useSelector } from "react-redux"

import { COMPONENT_TYPES, staticClasses } from "@/constants"
import {
  ConditionalWrapper,
  isContainer,
  isShopTheRoomCard,
  isTab,
  recursiveDataFunction,
} from "@/utils"
import { selectGenericState } from "@/store/features/genericSlice"
import {
  getActiveHash,
  handleHashchangeEvent,
  handleResizeEvent,
  onDocumentReady,
  setTabAnimation,
} from "@/components/Default/Tab/v1/TabHelper"
import {
  getEventInfo,
  handleTabClickDatalayer,
} from "@/components/Default/Tab/v1/TabAnalytics"
import styles from "@/components/ShopTheRoomCard/v1/index.module.scss"
import AemGrid from "@/components/AemGrid"
import useWindowDimensions from "@/hooks/useWindowDimensions"

const Tab = props => {
  const { data = {}, itemKey = "", ...extraProps } = props
  const [selectedTab, setTab] = useState("")
  const tabElement = useRef(null)
  const tabContainerRef = useRef(null)
  const { eventPageType, pageType } = useSelector(selectGenericState)
  const tabNavigationItems = useRef([])
  const { winWidth } = useWindowDimensions()

  const getDataLayerProps = item => {
    return recursiveDataFunction(item, COMPONENT_TYPES?.TEASER)
  }
  const onClickTab = (data, event, eventInfo) => {
    if (selectedTab !== data["cq:panelTitle"]) {
      setTab(data["cq:panelTitle"])
    }

    handleTabClickDatalayer(eventInfo)

    const { currentTarget = {} } = event
    const clickedTab = currentTarget
    // Move the tabbed navigation to the center of viewport when clicking
    clickedTab?.scrollIntoView({ block: "nearest", inline: "center" })
  }

  useEffect(() => {
    const selectedItemKey = data["activeItem"]
    if (data[":items"][selectedItemKey])
      setTab(data[":items"][selectedItemKey]["cq:panelTitle"])
  }, [data])

  useEffect(() => {
    const selectionBar = document?.querySelectorAll(".selectionBarcss")
    if (!selectionBar?.length) {
      onDocumentReady()
      getActiveHash(hashValue => {
        setTab(hashValue)
      })
      window?.addEventListener("resize", handleResizeEvent)
      window?.addEventListener("hashchange", handleHashchangeEvent)
    }

    return () => {
      window?.removeEventListener("resize", handleResizeEvent)
      window?.removeEventListener("hashchange", handleHashchangeEvent)
    }
  }, [])

  const getActiveTabIndex = data => {
    return (
      data[":itemsOrder"]
        .map(key => {
          const item = data[":items"][key]
          const panelTitle = item["cq:panelTitle"] || ""
          return panelTitle
        })
        .indexOf(selectedTab) ?? ""
    )
  }

  useEffect(() => {
    if (data && selectedTab) {
      const activeTabIndex = getActiveTabIndex(data)
      if (activeTabIndex !== "") {
        const tabNavEl = tabNavigationItems?.current[activeTabIndex]
        setTabAnimation(tabNavEl)
      }
    }
  }, [data, selectedTab])

  return (
    <div className="cmp-tabs" data-cmp-is="tabs" ref={tabContainerRef}>
      <ol
        role="tablist"
        className="cmp-tabs__tablist"
        aria-multiselectable="false"
        ref={tabElement}
      >
        <div
          className={`selectionBarcss${
            data?.appliedCssClassNames?.includes("vertical-tabs") &&
            winWidth >= 1024
              ? " selectionBar-vertical"
              : " selectionBar-horizontal"
          }`}
        />
        {data[":itemsOrder"]
          ? data[":itemsOrder"]?.map((key = "", index = 0) => {
              const item = data[":items"][key]
              const panelTitle = item["cq:panelTitle"] || ""
              const dataLayerProps = getDataLayerProps(item)
              const {
                componentPosition = "",
                eventName = "",
                componentLinkType = "",
                eventAction = "",
              } = dataLayerProps ?? {}

              return (
                <li
                  ref={ref => {
                    tabNavigationItems.current[index] = ref
                  }}
                  role="tab"
                  aria-expanded="false"
                  className={`cmp-tabs__tab ${
                    (selectedTab === "" && index === 0) ||
                    selectedTab == panelTitle
                      ? "cmp-tabs__tab--active"
                      : ""
                  }`}
                  id={panelTitle}
                  tabIndex="0"
                  key={panelTitle}
                  data-cmp-hook-tabs="tab"
                  data-gbh-clickable="true"
                  onClick={event => {
                    const eventInfo = getEventInfo(
                      componentPosition,
                      eventName,
                      componentLinkType,
                      eventAction,
                      eventPageType,
                      pageType,
                      item
                    )
                    onClickTab(item, event, eventInfo)
                  }}
                >
                  {panelTitle}
                </li>
              )
            })
          : null}
      </ol>
      {data[":itemsOrder"]
        ? data[":itemsOrder"]?.map((key = "", index = 0) => {
            const item = data[":items"][key]
            const { id = "" } = item
            const panelTitle = item["cq:panelTitle"] || ""
            const hashValue = panelTitle?.replaceAll(" ", "-")

            // set for rerendering child component when nested tabs are present for recalculating the active indicator
            const hasNestedTab = item[":itemsOrder"].some(childKey => {
              const obj = item[":items"][childKey]
              return isTab(obj)
            })

            const isShopTheRoom = item?.[":itemsOrder"]?.some(itemKey => {
              const itemData = item[":items"][itemKey]
              return isShopTheRoomCard(itemData)
            })
            return (
              <div
                role="tabpanel"
                aria-label={panelTitle}
                data-hashid={hashValue}
                className={`cmp-tabs__tabpanel ${
                  selectedTab == panelTitle ? "cmp-tabs__tabpanel--active" : ""
                }`}
                key={`navItem-${index}`}
                data-cmp-hook-tabs="tabpanel"
                aria-hidden={selectedTab !== panelTitle}
              >
                <ConditionalWrapper
                  condition={isShopTheRoom}
                  wrapper={children => (
                    <div className={styles.shopTheRoomWrapper}>{children}</div>
                  )}
                >
                  <ConditionalWrapper
                    condition={isContainer(item)}
                    wrapper={children => (
                      <div
                        className={`${
                          isContainer(item) ? staticClasses.CONTAINER : ""
                        } ${item.appliedCssClassNames}`}
                      >
                        <div className="cmp-container">
                          {item?.gridClassNames ? (
                            <div className={item?.gridClassNames}>
                              {children}
                            </div>
                          ) : (
                            { children }
                          )}
                        </div>
                      </div>
                    )}
                  >
                    <AemGrid
                      {...(hasNestedTab ? { key: selectedTab } : {})}
                      data={item}
                      containerId={id}
                      itemKey={key ? key : itemKey}
                      {...extraProps}
                    />
                  </ConditionalWrapper>
                </ConditionalWrapper>
              </div>
            )
          })
        : null}
    </div>
  )
}

export default Tab
