import React from "react"

import cartEmptyIcon from "@/public/icons/cart-icon-empty.svg"
import styles from "@/components/Shared/index.module.scss"

const BrandHeaderFiller = () => {
  const avatarIcon = "/icons/avatar.svg"

  return (
    <div className={styles.utilityNavHeaderWrapper}>
      <div className="utilitynavheader">
        <div className="gbh-utility-nav">
          <div className="gbh-utility-navbar">
            <ul role="list">
              <li className="gbh-nav__findStore">
                <a
                  role="button"
                  tabIndex={0}
                  aria-live="polite"
                  aria-labelledby="find-ada"
                  className="gbh-nav__findStore--link cmp-find-place"
                >
                  Find a Store
                </a>
              </li>
              <li
                className="gbh-nav__region region_arrowdropdown"
                id="united_states"
              >
                <a
                  role="button"
                  tabIndex={0}
                  aria-labelledby="country-ada"
                  aria-expanded="false"
                  className="gbh-nav__region--link active-country  "
                >
                  {`${process.env.NEXT_PUBLIC_COUNTRYNAME} -EN`}
                </a>
              </li>
              <li className="gbh-nav__signin">
                <div className="UtilityWidgets_utilityWidgetsWrapper__Wz0ZS">
                  <div
                    role="list"
                    className="utility-widgets utility-widgets__brand-switcher"
                  >
                    <div
                      role="listitem"
                      className="auth-widget auth-widget__menu AuthUtilityNav_authUtilityNavWrapper__iJgYU"
                    >
                      <div className="auth-nav" aria-expanded="false">
                        <img
                          tabIndex={0}
                          role="button"
                          name="avatarIcon"
                          aria-label="Sign In Collapsed"
                          src={avatarIcon}
                          alt="Sign In"
                        />
                        <div className="auth-nav__menu  " aria-hidden="true" />
                      </div>
                    </div>
                    <div role="listitem" className="wishlist-widget">
                      <a
                        aria-label="View your wishlist"
                        className="gbh-data-layer"
                        href=""
                      >
                        <img
                          alt="View your wishlist"
                          aria-hidden="true"
                          role="presentation"
                          src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4NCjxzdmcgd2lkdGg9IjE1cHgiIGhlaWdodD0iMTRweCIgdmlld0JveD0iMCAwIDE1IDE0IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPg0KICAgIDx0aXRsZT5JY29uL1dpc2hsaXN0PC90aXRsZT4NCiAgICA8ZyBpZD0iU3ltYm9scyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+DQogICAgICAgIDxnIGlkPSJCdXR0b24vSW1hZ2UtSWNvbnMvV2lzaGxpc3QtRW1wdHkvRGFyay1HcmF5LUJHIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTEuMDAwMDAwLCAtMTEuMDAwMDAwKSIgZmlsbD0iI0ZGRkZGRiI+DQogICAgICAgICAgICA8ZyBpZD0iSWNvbi9XaXNobGlzdCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTEuMDAwMDAwLCAzLjAwMDAwMCkiPg0KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik04LjE0MDYwNzE0LDkuNjg4NDgxOTcgTDcuNTAxLDEwLjMzNCBMNi44NjE1OTM2OSw5LjY4ODQ4MTk3IEM1LjI5MjQyMzUyLDguMTAzODM5MzQgMi43NDYyNDQ3OSw4LjEwMzgzOTM0IDEuMTc3MDc0NjMsOS42ODg0ODE5NyBDLTAuMzQ4NzgxOTU4LDExLjIyOTM4MzkgLTAuMzk2MjUxOTU5LDEzLjcxODU4MzQgMS4wNjQzMjIzNiwxNS4yOTcwNDQyIEwxLjE3MDcxODUyLDE1LjQwODU3MDcgTDcuNTAxMTEzNDQsMjEuODAxNTAzMyBMMTMuODA4NDgzOSwxNS40MzIxOTk4IEwxMy45MTUxODc4LDE1LjMyMTE0MDggQzE1LjM5NTU5NjgsMTMuNzIxNzEyNSAxNS4zNTM5NzAzLDExLjIzMjQwMSAxMy44MjUxMjYyLDkuNjg4NDgxOTcgQzEyLjI1NTk1Niw4LjEwMzgzOTM0IDkuNzA5Nzc3MzEsOC4xMDM4MzkzNCA4LjE0MDYwNzE0LDkuNjg4NDgxOTcgWiBNMTMuMTE0NTU4OSwxMC4zOTIxMTEzIEMxNC4yNjY2NjkyLDExLjU1NTU4MTYgMTQuMjk4MTAyMiwxMy40MzUzMTE1IDEzLjE4NzU0NTEsMTQuNjM1MjQ1MyBMMTMuMDkyNTIwMiwxNC43MzQxMDUzIEw3LjUwMSwyMC4zOCBMMS44ODc2NDE4OSwxNC43MTE0NzgzIEwxLjc5Mjk1NDA5LDE0LjYxMjE4MzEgQzAuNzAxOTMzNDQxLDEzLjQzMzAxMzYgMC43Mzc3ODAxMDEsMTEuNTUzMzExIDEuODg3NjQxODksMTAuMzkyMTExMyBDMy4wNjU1MDk0NSw5LjIwMjYyOTU4IDQuOTczMTU4ODcsOS4yMDI2Mjk1OCA2LjE1MTAyNjQyLDEwLjM5MjExMTMgTDcuNTAxMTAwNDIsMTEuNzU1NDk3NCBMOC44NTExNzQ0MSwxMC4zOTIxMTEzIEMxMC4wMjkwNDIsOS4yMDI2Mjk1OCAxMS45MzY2OTE0LDkuMjAyNjI5NTggMTMuMTE0NTU4OSwxMC4zOTIxMTEzIFoiIGlkPSJQYXRoIj48L3BhdGg+DQogICAgICAgICAgICA8L2c+DQogICAgICAgIDwvZz4NCiAgICA8L2c+DQo8L3N2Zz4="
                        />
                      </a>
                    </div>
                    <div role="listitem" className="cart-indicator-widget">
                      <div className="cart-indicator false">
                        <a
                          aria-label="View your cart containing 0 item"
                          role="link"
                          className="gbh-data-layer"
                          href="/en/cart"
                        >
                          <img src={cartEmptyIcon.src} alt="Cart" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BrandHeaderFiller
