import { getComponentZone } from "@/components/Default/Video/videoAnalytics"

export const getEventInfo = (
  componentPosition = "",
  eventName = "",
  componentLinkType = "",
  eventAction = "",
  eventPageType = "",
  pageType = "",
  item = {}
) => {
  const panelTitle = item["cq:panelTitle"]?.toLowerCase() || ""
  const isSupportPage = eventPageType.includes("support")
  const tabContainer = document?.querySelectorAll(".tabs")
  let zoneVal
  tabContainer?.forEach(tabs => {
    if (!tabs?.classList?.contains("gbh-global-tab")) {
      zoneVal = getComponentZone(tabs?.parentElement)
    }
  })
  const eventInfo = {
    clickInternalLinks: "true",
    internalLinkPosition: "tabs",
    internalLinkZoneName: `${eventPageType}:${zoneVal}`,
    internalLinkURL: "n/a",
    internalLinkName: `${panelTitle}`,
    eventName: `${eventPageType}:${zoneVal}:${
      eventName || isSupportPage
        ? "submenu"
        : panelTitle || "submenu navigation link"
    }`,
    internalLinkType: `${eventPageType}:${componentLinkType || "navigation"}`,
    eventType: "tab",
    eventAction: `${eventPageType}:${zoneVal}:${
      eventAction || isSupportPage
        ? "submenu"
        : panelTitle || "submenu navigation link"
    }`,
  }
  if (eventPageType.includes("find a store")) {
    eventInfo.internalLinkType = "find a store:navigation"
    eventInfo.eventName = `${eventPageType}:${zoneVal}:tab navigation`
    eventInfo.eventAction = `${eventPageType}:${zoneVal}:tab navigation`
  }
  return eventInfo
}

export const handleTabClickDatalayer = eventInfo => {
  const { adobeDataLayer: { getState } = {} } = window ?? {}
  const page = getState ? getState("page") : {}
  window?.adobeDataLayer?.push({
    event: "cmp:click",
    eventInfo,
    page,
  })
}

export const handleDatalayer = ({
  componentPosition = "",
  eventName = "",
  componentLinkType = "",
  eventAction = "",
  eventPageType = "",
  item = {},
  selectedTab = "",
  panelTitle = "",
}) => {
  if (selectedTab != panelTitle) {
    let zoneVal
    const tabContainer = document?.querySelectorAll(".tabs")
    tabContainer?.forEach(tabs => {
      if (!tabs?.classList?.contains("gbh-global-tab")) {
        zoneVal = getComponentZone(tabs?.parentElement)
      }
    })

    const replacements = {
      zone: zoneVal,
      "global header": zoneVal,
    }
    const regex = new RegExp(Object.keys(replacements)?.join("|"), "g")

    const { adobeDataLayer: { getState } = {} } = window ?? {}
    const page = getState ? getState("page") : {}
    const eventInfoData = getEventInfo(
      componentPosition,
      eventName,
      componentLinkType,
      eventAction,
      eventPageType,
      item
    )?.replaceAll(regex, match => replacements[match])
    window?.adobeDataLayer?.push({
      event: "cmp:click",
      eventInfo: JSON.parse(eventInfoData),
      page: page,
    })
  }
}
