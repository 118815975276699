import Button from "@/components/core/Button/Button"
import DOMPurify from "isomorphic-dompurify"
import styles from "@/components/ProductEnquiry/ProductEnquiryView/index.module.scss"
import { backToHomeAnalytics } from "@/components/ProductEnquiry/analytics"

const Confirmation = props => {
  const { staticText } = props
  const onClick = () => {
    window.location.href = "/"
    backToHomeAnalytics(window.location.origin)
  }
  return (
    <div className={styles.productEnquiryLanding}>
      <div className="product-enquiry__success-box">
        <h1 className="product-enquiry__success-message">
          {staticText?.messageHeaderSuccess}
        </h1>
        <div
          className="product-enquiry__success-description"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(staticText?.messageDescriptionSuccess),
          }}
        />
        <div className="container">
          <Button
            customClass={"kf-react-button"}
            className={"kf-react-button button large black flexible"}
            role="button"
            id="submit-contact-form-button"
            onClick={onClick}
            type="black"
            label={staticText?.backToHomePage}
          />
        </div>
      </div>
    </div>
  )
}

export default Confirmation
