export const getDataLayerValues = (
  isSSR,
  pageName = "",
  link = "",
  title = "",
  linkName = ""
) => {
  if (!isSSR && window) {
    const dataLayer = window?.adobeDataLayer ?? {}
    const page = dataLayer?.getState().page ?? {}
    if (Object.keys(page).length) {
      const eventInfo = {
        clickInternalLinks: "true",
        eventAction: `${pageName}:${title.toLowerCase()}:${linkName.toLowerCase()}`,
        eventMsg: "n/a",
        eventName: `${pageName}:${title.toLowerCase()}:${linkName.toLowerCase()}`,
        eventStatus: "n/a",
        eventType: "teaser",
        internalLinkName: `${linkName.toLowerCase()}`,
        internalLinkPosition: `${title.toLowerCase()}`,
        internalLinkType: `${pageName}:${linkName.toLowerCase()}`,
        internalLinkURL: `${link}`,
        internalLinkZoneName: `${pageName}:${title.toLowerCase()}`,
      }
      return JSON.stringify(eventInfo)
    }
  }
  return ""
}
