import DynamicImage from "@/components/core/Image/DynamicImage"

const Image = props => {
  const {
    data: {
      id: uniqueId = "",
      alt = "",
      src: imgUrl = "",
      imageComponentProperties = {
        src: "",
        alt: "",
      },
    } = {},
  } = props

  return (
    <div className="image">
      <div className="eaem-bounding-div">
        <div className="cmp-image">
          <DynamicImage
            {...{
              uniqueId,
              imgUrl: imgUrl || imageComponentProperties.src,
              alt: alt || imageComponentProperties.alt,
              className: "cmp-image__image transition",
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Image
